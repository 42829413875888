import type { ReactElement } from 'react';

import QuickActions from '../../components/QuickActions';
const items = [
  {
    text: 'Carteirinha Digital',
    icon: 'card.png',
    href: '/carteirinha',
  },
  {
    text: 'Lista de Procedimentos',
    icon: 'list.png',
  },
  {
    text: 'Rede Credenciada',
    icon: 'network.png',
    href: '/rede-credenciada',
  },
  {
    text: 'Cobertura do Plano',
    icon: 'insurance.png',
  },
  {
    text: 'Fale Conosco',
    icon: 'chat.png',
    href: '/faleconosco',
  },
  {
    text: 'Saiba Mais',
    icon: 'plus.png',
    href: '/quero-contratar',
    // useAnchor: true,
    // blank: true,
    reversed: true,
  },
];

const ActionsSection2 = (): ReactElement => (
  <section className="mt-10 mb-32">
    <QuickActions items={items} />
  </section>
);

export default ActionsSection2;
