import {
  Navigate,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';

import { RootLayout } from '../components/RootLayout';
import HomePage from '../pages/Home/index';
import AboutPage from '../pages/About';
import { AccreditedNetwork } from '../pages/AccreditedNetwork';
import BeneficiaryPage from '../pages/Beneficiary';
import CompaniesPage from '../pages/Companies';
import DentistPage from '../pages/Dentist';
import { ErrorPage } from '../pages/ErrorPage';
import FAQPage from '../pages/FAQ';
import { HirePlanPage } from '../pages/HirePlan';
import { UpdateDependentGDFPage } from '../pages/UpdateDependentGDF';
import OuvidoriaPage from '../pages/Ouvidoria';
import FaleConoscoPage from '../pages/FaleConosco';
import Plan from '../pages/Plans';
import PrivacyPage from '../pages/Privacy';
import { ProposalPage } from '../pages/Proposal';
import Transparency from '../pages/Transparency';
import { HirePlanGDFPage } from '../pages/HirePlanGDF';
import { HirePlanGDFPageCopy } from '../pages/HirePlanGDF copy';
import GaleriaRedirect from '../pages/redirecionamento-Galeria';
import UnafiscoRedirect from '../pages/redirecionamento-unafisco';
import HiringPage from '../pages/Hiring';
import Carteirinha from '../pages/CarteirinhaDigital';
import { CredentialNetPage } from '../pages/CredentialNetPage';
import SolicitacaoScreen from '../pages/teste/solicitationScreen';
import { ComponenteConfirmacao } from '../teste';
import { ConfirmPAP } from '../components/HirePlanForm/ServidorGDFCopy/steps/ConfirmPAP';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />} errorElement={<ErrorPage />}>
      <Route path="/" element={<HomePage />} />
      <Route path="/faq" element={<FAQPage />} />
      <Route path="/ouvidoria" element={<OuvidoriaPage />} />
      <Route path="/faleconosco" element={<FaleConoscoPage />} />
      <Route path="/transparencia" element={<Transparency />} />
      <Route path="/dentist" element={<DentistPage />} />
      <Route path="/beneficiario" element={<BeneficiaryPage />} />
      <Route path="/empresas" element={<CompaniesPage />} />
      <Route path="/quero-contratar" element={<HiringPage />} />
      <Route path="/transparencia" element={<Transparency />} />
      <Route path="/quem-somos" element={<AboutPage />} />
      <Route path="/politica-de-privacidade" element={<PrivacyPage />} />
      <Route path="/contratar-plano" element={<HirePlanPage />} />
      <Route path="/planos" element={<Plan />} />
      <Route path="/carteirinha" element={<Carteirinha />} />
      <Route path="/rede-credenciada" element={<AccreditedNetwork />} />
      <Route path="/galeria2025" element={<GaleriaRedirect />} />
      <Route path="/rede-atendimento" element={<CredentialNetPage />} />
      <Route
        path="/unafisco/carteirinhadigital"
        element={<UnafiscoRedirect />}
      />
      {/* não existe essa rota sem token */}
      <Route
        element={<HirePlanPage />}
        path="/contratar-plano/:token"
        id="token"
        loader={async ({ params }) => {
          //TODO env
          const data = await fetch(
            `${process.env.REACT_APP_OG_GET_PLAN_DETAILS_INDIVIDUAL}${params.token}`,
          );
          if (data.status === 500 || data.status === 404) {
            throw new Response('Not Found', { status: 404 });
          }
          return data;
        }}
        errorElement={<ErrorPage />}
      />
      <Route
        element={<HirePlanPage />}
        path="empresa/contratar-plano/:token"
        id="token-empresa"
        loader={async ({ params }) => {
          //TODO env
          const data = await fetch(
            `${process.env.REACT_APP_OG_GET_PLAN_DETAILS_COMPANY}${params.token}`,
          );
          if (data.status === 500 || data.status === 404) {
            throw new Response('Not Found', { status: 404 });
          }
          return data;
        }}
        errorElement={<ErrorPage />}
      />
      <Route
        element={<HirePlanGDFPage />}
        path="servidor/contratar-plano/:token"
        id="token-servidor"
        loader={async ({ params }) => {
          const data = await fetch(
            `${process.env.REACT_APP_OG_GET_PLAN_DETAILS_SERVER}${params.token}`,
          );
          if (data.status === 500 || data.status === 404) {
            throw new Response('Not Found', { status: 404 });
          }
          return data;
        }}
        errorElement={<ErrorPage />}
      />
      <Route
        element={<HirePlanGDFPageCopy />}
        path="servidorPaP/contratar-plano/:token"
        id="token-servidorCopy"
        loader={async ({ params }) => {
          const data = await fetch(
            `${process.env.REACT_APP_OG_GET_PLAN_DETAILS_SERVER}${params.token}`,
          );
          if (data.status === 500 || data.status === 404) {
            throw new Response('Not Found', { status: 404 });
          }
          return data;
        }}
        errorElement={<ErrorPage />}
      />
      <Route
        element={<UpdateDependentGDFPage />}
        path="servidor/incluir-dependente/:token"
        id="token-dependente"
        loader={async ({ params }) => {
          const data = await fetch(
            `${process.env.REACT_APP_OG_GET_PLAN_DETAILS_SERVER}${params.token}`,
          );
          if (data.status === 500 || data.status === 404) {
            throw new Response('Not Found', { status: 404 });
          }
          return data;
        }}
        errorElement={<ErrorPage />}
      />
      <Route path="/proposta" element={<ProposalPage />} />
      <Route path="/solicitarConf" element={<SolicitacaoScreen />} />
      <Route path="/confirmar/:id" element={<ComponenteConfirmacao />} />
      <Route path="/confirm-pap" element={<ConfirmPAP />} />
    </Route>,
  ),
);
