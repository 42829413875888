export const breadcrumbSteps = [
  {
    step: 1,
    name: 'Cadastro beneficiário',
  },
  {
    step: 2,
    name: 'Dependentes',
    description: 'Você pode adicionar quantos dependentes desejar',
  },
  {
    step: 3,
    name: 'Turbine seu plano',
    description: 'Você pode adicionar coberturas ao plano selecionado',
  },
  {
    step: 4,
    name: 'Pagamento',
    description:
      'Escolha uma forma de pagamento  e um link de pagamento será enviado para seu email após o envio desse formulário.',
  },
];

export const bussinessBreadcrumbSteps = [
  {
    step: 1,
    name: 'Dados da Empresa',
  },
  {
    step: 2,
    name: 'Funcionários',
    description: 'Você pode adicionar quantos funcionários desejar',
  },
  {
    step: 3,
    name: 'Arquivos',
    description: 'Você pode adicionar quantos funcionários desejar',
  },
  {
    step: 4,
    name: 'Pagamento',
    description:
      'Escolha uma forma de pagamento  e um link de pagamento será enviado para seu email após o envio desse formulário.',
  },
];
export const GDFBreadcrumbSteps = [
  {
    step: 1,
    name: 'Validação Servidor',
  },
  {
    step: 2,
    name: 'Confirmação dos Dados',
  },
  {
    step: 3,
    name: 'Termo de aceite',
    description: 'Aceitar o termo de uso',
  },
  {
    step: 4,
    name: 'Finalização',
    description: 'Finalizar a compra',
  },
];
export const GDFBreadcrumbStepsCopy = [
  {
    step: 1,
    name: 'Validação Servidor',
  },
  {
    step: 2,
    name: 'Confirmação dos Dados',
  },
  {
    step: 3,
    name: 'Aguardando Confirmação',
    description: 'Aceitar o termo de uso',
  },
  {
    step: 4,
    name: 'Finalização',
    description: 'Finalizar a compra',
  },
];
export const DependentGDFBreadcrumbSteps = [
  {
    step: 1,
    name: 'Validação de associado',
  },
  {
    step: 2,
    name: 'Confirmação dos Dados',
  },
  {
    step: 3,
    name: 'Termo de aceite',
    description: 'Aceitar o termo de uso',
  },
  {
    step: 4,
    name: 'Finalização',
    description: 'Finalizar a compra',
  },
];
