import { create } from 'zustand';

type PAP = {
  PAP: boolean;
  setId: (PAP: boolean) => void;
};

export const usePaPStore = create<PAP>(set => ({
  PAP: false,
  setId: PAP => set(() => ({ PAP: PAP })),
}));
