import type { ReactElement } from 'react';

import DownloadBox from './DownloadBox';

const SurveySection = (): ReactElement => (
  <section className="w-full p-5 bg-new-gray">
    <h3
      className="text-[40px] leading-[60px] text-center text-neutral-with max-[1200px]:text-2xl max-[1200px]:max-w-[266px]"
      style={{ color: '#272d65' }}
    >
      Pesquisa de Satisfação de Beneficiários
    </h3>
    <div className="flex justify-center gap-5 max-[840px]:flex-col mb-10">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-5 max-[799px]:w-full">
        <DownloadBox
          title="Pesquisa de Satisfação 2025"
          info="Ano Base 2024"
          href="https://www.odontogroup.com.br/wp-content/uploads/2025/03/OdontoGroup-Pesquisa-IDSS-2025-Base-2024.pdf"
        />
        <DownloadBox
          title="Parecer da Auditoria Independente sobre a Pesquisa de Satisfação 2025"
          info="Ano Base 2024"
          href="https://www.odontogroup.com.br/wp-content/uploads/2025/03/Parecer-de-Auditoria_2025.pdf"
        />
        <DownloadBox
          title="Pesquisa de Satisfação 2024"
          info="Ano Base 2023"
          href="https://www.odontogroup.com.br/wp-content/uploads/2024/03/OdontoGroup-Pesquisa-IDSS-2024-Base-2023.pdf"
        />
        <DownloadBox
          title="Parecer da Auditoria Independente sobre a Pesquisa de Satisfação 2024"
          info="Ano Base 2023"
          href="https://www.odontogroup.com.br/wp-content/uploads/2024/03/Parecer-de-Auditoria_2024.pdf"
        />
        <DownloadBox
          title="Pesquisa de Satisfação 2023"
          info="Ano Base 2022"
          href="https://www.odontogroup.com.br/wp-content/uploads/2023/08/OdontoGroup-Pesquisa-IDSS-2023-Base-2022.pdf"
        />
        <DownloadBox
          title="Parecer da Auditoria Independente sobre a Pesquisa de Satisfação 2023"
          info="Ano Base 2022"
          href="https://www.odontogroup.com.br/wp-content/uploads/2023/08/Parecer-de-Auditoria_2023.pdf"
        />
        <DownloadBox
          title="Pesquisa de Satisfação 2022"
          info="Ano Base 2021"
          href="https://www.odontogroup.com.br/wp-content/uploads/2022/04/Pesquisa_Satisfacao_Beneficiarios_2022_Relatorio.pdf"
        />
        <DownloadBox
          title="Parecer da Auditoria Independente sobre a Pesquisa de Satisfação 2022"
          info="Ano Base 2021"
          href="https://www.odontogroup.com.br/wp-content/uploads/2022/04/Pesquisa_Satisfacao_Beneficiarios_2022__Parecer_de_Auditoria_Independente.pdf"
        />
        <DownloadBox
          title="Pesquisa de Satisfação 2021"
          info="Ano Base 2020"
          href="https://www.odontogroup.com.br/wp-content/uploads/2021/12/Pesquisa_Satisfacao_Beneficiarios_2021_Relatorio.pdf"
        />
        <DownloadBox
          title="Parecer da Auditoria Independente sobre a Pesquisa de Satisfação 2021"
          info="Ano Base 2020"
          href="https://www.odontogroup.com.br/wp-content/uploads/2021/12/Pesquisa_Satisfacao_Beneficiarios_2021__Parecer_de_Auditoria_Independente.pdf"
        />
      </div>
    </div>
  </section>
);

export default SurveySection;
