import { ReactElement, useEffect, useState } from 'react';
import { useRouteLoaderData } from 'react-router-dom';
import { useBoostedPlanDataStore } from '../../../stores/useBoostedPlanDataStore';
import { useNortheastPlanDataStore } from '../../../stores/useNortheastPlanDataStore';
import { usePlanLoadingStore } from '../../../stores/usePlanLoadingStore';
import PlanLoading from '../../icons/PlanLoading';
import { useVidaCountStore } from '../../../stores/useQuantidadeVidas';

export function MainPurchaseSummaryContent(): ReactElement {
  const [empresa, setEmpresa] = useState(false);
  const individualPlanData: any = useRouteLoaderData('token');
  const bussinessPlanData: any = useRouteLoaderData('token-empresa');
  const GDFPlanData: any = useRouteLoaderData('token-servidor');
  const GDFPlanDataCopy: any = useRouteLoaderData('token-servidorCopy');
  let initialPlanData = individualPlanData;
  if (individualPlanData != undefined) {
    initialPlanData = individualPlanData;
  } else if (GDFPlanDataCopy) {
    initialPlanData = GDFPlanDataCopy;
  } else if (bussinessPlanData != undefined) {
    initialPlanData = bussinessPlanData;
  } else {
    initialPlanData = GDFPlanData;
  }
  const boostedPlanData = useBoostedPlanDataStore(state => state.data);
  const northeastPlanData = useNortheastPlanDataStore(state => state.data);

  const [data, setData] = useState(initialPlanData);
  const [anual, setAnual] = useState(false);
  const Produto_an = [989, 990, 991, 992];
  const [valor, setValor] = useState('0,00');
  const Vidas = useVidaCountStore(x => x.count);
  const setVidas = useVidaCountStore(x => x.setVidaCount);

  useEffect(() => {
    setVidas(1);
    if (bussinessPlanData) {
      setEmpresa(true);
    } else {
      setEmpresa(false);
    }
    console.log('FOI!!');
  }, []);

  useEffect(() => {
    if (Object.keys(boostedPlanData).length !== 0 && boostedPlanData != data) {
      console.log('passou aqui boost');
      const valorNumber = boostedPlanData.formasPagamento[0].vl_valor;
      const roundedNum = (valorNumber * 1).toFixed(2);
      const formattedNum = roundedNum.replace('.', ',');
      setValor(formattedNum);
      setData(boostedPlanData);
    } else if (
      Object.keys(northeastPlanData).length !== 0 &&
      northeastPlanData != data
    ) {
      console.log('passou aqui north');
      const valorNumber = northeastPlanData.formasPagamento[0].vl_valor;
      const roundedNum = (valorNumber * 1).toFixed(2);
      const formattedNum = roundedNum.replace('.', ',');
      setValor(formattedNum);
      setData(northeastPlanData);
    } else {
      console.log('passou aqui initial');
      const valorNumber = initialPlanData.formasPagamento[0].vl_valor;
      const roundedNum = (valorNumber * 1).toFixed(2);
      const formattedNum = roundedNum.replace('.', ',');
      setValor(formattedNum);
      setData(initialPlanData);
    }
  }, [boostedPlanData, northeastPlanData, initialPlanData]);

  const extractNumber = (text: string): number => {
    const match = text.match(/\d+/); // Encontra o primeiro número na string
    return match ? parseInt(match[0], 10) : 0; // Converte o número encontrado em inteiro ou retorna 0
  };

  // Ordenar carencias com base no número extraído
  const sortedCarencias = [...data.carencias].sort((a, b) => {
    const numA = extractNumber(a.carencia.nm_descricaocarencia);
    const numB = extractNumber(b.carencia.nm_descricaocarencia);
    return numA - numB;
  });

  useEffect(() => {
    const produto = data?.produtoComercial?.id_prodcomerc;
    if (Produto_an.includes(produto)) {
      const roundedNum = (
        Math.round(data.formasPagamento[0].vl_valor * 1200) / 100
      ) // Multiplica o valor por 12, com um desconto de 10% e arredonda para 2 casas decimais utilizado dessa forma para evitar problemas com os calculos em javascript.
        .toFixed(2);
      const formattedNum = roundedNum.replace('.', ',');
      setValor(formattedNum);
      setAnual(true);
    } else {
      setAnual(false);
    }
  }, [data]);

  useEffect(() => {
    if (Vidas > 1) {
      const valorNumber = data.formasPagamento[0].vl_valor;
      const roundedNum = (valorNumber * Vidas).toFixed(2);
      const formattedNum = roundedNum.replace('.', ',');
      setValor(formattedNum);
    } else {
      setValor(data.formasPagamento[0].vl_valor.replace('.', ','));
    }
  }, [Vidas]);

  const loading = usePlanLoadingStore(state => state.loading);

  return (
    //TODO Mostrar mensagem que o plano foi trocado - planBoost
    <>
      <h3 className="text-high-emphasis text-xl">Resumo da compra</h3>
      <span className="flex justify-between uppercase text-medium-emphasis text-xs font-normal">
        <span className="block">
          <span className="block">Plano escolhido:</span>
          <strong className="block text-neutral-600 normal-case text-sm font-normal">
            {data.produtoComercial.nm_prodcomerc}
          </strong>
        </span>
        <span className="block">
          <span className="block">Vidas:</span>
          <strong className="block text-neutral-600 normal-case text-sm font-normal">
            {Vidas}
          </strong>
        </span>
      </span>
      {/* <span className="uppercase text-medium-emphasis text-xs font-normal block">
        <span className="block mb-1">Valor plano:</span>
        <div className="w-[95px] h-5 bg-gradient-to-r from-[#EBE8E8] to-[#FAFAFA] rounded-xl" />
      </span> */}
      {loading ? (
        <PlanLoading />
      ) : anual ? (
        <div className="h-[95px] bg-[#F8F8FB] py-3 flex flex-col justify-center items-center ">
          <span className="uppercase text-secondary-main rounded-xl block mb-[11px]">
            Valor total
          </span>
          {/* <span className="text-[0.625rem] mb-1">A partir de</span> */}
          <strong className="font-normal text-base text-high-emphasis">
            R${' '}
            <span className="text-[2rem] font-semibold">
              {
                //TODO pegar valor da forma de pagamento selecionada e validar se for boleto nu_pagamento_unico
              }
              {valor}
            </span>{' '}
            por ano
          </strong>
        </div>
      ) : empresa ? (
        <div className="h-[95px] bg-[#F8F8FB] py-3 flex flex-col justify-center items-center ">
          <span className="uppercase text-secondary-main rounded-xl block mb-[11px]">
            Valor total
          </span>
          {/* <span className="text-[0.625rem] mb-1">A partir de</span> */}
          <strong className="font-normal text-base text-high-emphasis">
            R${' '}
            <span className="text-[2rem] font-semibold">
              {
                //TODO pegar valor da forma de pagamento selecionada e validar se for boleto nu_pagamento_unico
              }
              {valor}
            </span>{' '}
            por mês
          </strong>
        </div>
      ) : (
        <div className="h-[95px] bg-[#F8F8FB] py-3 flex flex-col justify-center items-center ">
          <span className="uppercase text-secondary-main rounded-xl block mb-[11px]">
            Valor total
          </span>
          <strong className="font-normal text-base text-high-emphasis">
            R$ <span className="text-[2rem] font-semibold">{valor}</span> por
            mês
          </strong>
        </div>
      )}
      <div className="">
        <span className="text-[0.625rem] text-[#777777]">Carência</span>
        {sortedCarencias
          ? sortedCarencias.map((carencias: any) => (
              <p
                key={carencias.carencia.nm_descricaocarencia}
                className="text-xs text-[#394146]"
              >
                {carencias.carencia.nm_descricaocarencia}
              </p>
            ))
          : ''}
      </div>
    </>
  );
}
