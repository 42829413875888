import { ReactElement } from 'react';

import DentistSearch from '../../components/DentistSearch';
import { PageHelmet } from '../../components/PageHelmet';
import Banner from './Banner';
import IndicationForm from './IndicationForm';

export function AccreditedNetwork(): ReactElement {
  return (
    <div className="h-full min-h-[calc(100vh-549px)]">
      <PageHelmet title="Rede Credenciada | OdontoGroup" />

      <Banner
        page="rede-credenciada"
        title="Rede Credenciada"
        mainText="Precisa de ajuda para saber qual especialidade odontológica você precisa?"
      />

      <DentistSearch />
      <div className="min-[1059px]:w-[1011px] max-[1059px]:px-6 mx-auto">
        <section className="flex flex-col items-center justify-center min-[1059px]:pt-[116px] min-[1059px]:pb-[219px] py-20 ">
          {/*  */}
        </section>
      </div>

      <h3 className="text-center mb-7 font-semibold max-[1058px]:text-lg text-[2.625rem] text-primary-main min-[1059px]:mx-[20%] mx-[10%]">
        Indique o seu dentista para fazer parte da rede credenciada da
        Odontogroup!
      </h3>

      <section className="bg-[url('/public/assets/images/hiring/ginger.png')] bg-contain bg-no-repeat min-[1059px]:min-h-[900px] p-3 min-[1059px]:p-10 max-[1059px]:mb-24">
        <div className="bg-white max-h-[40%] rounded p-10 max-[748px]:p-5 min-[748px]:min-w-[676px] shadow-card min-[1059px]:max-w-[30%] mt-24">
          <IndicationForm />
        </div>
      </section>
    </div>
  );
}
