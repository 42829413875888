import { ReactElement } from 'react';

import { useHirePlanForm } from '../../../../contexts/HirePlanFormProviderGdf';
import { GoForwardButton } from '../../GoForwardButton';
import { useWindowSizeStore } from '../../../../stores/useWindowSizeStore';
import { useLoadingStore } from '../../../../stores/useLoadingStore';
import { ArrowLeftIcon } from '../../../icons/ArrowLeftIcon';
import { CartIcon } from '../../../icons/CartIcon';
import Loading from '../../../icons/Loading';

export function ConfirmForm(): ReactElement {
  const { handleSubmit, navigateToProposta } = useHirePlanForm();
  const loading = useLoadingStore(state => state.loading);

  const width = useWindowSizeStore(state => state.width);

  return (
    <form
      action=""
      className="max-w-[766px] w-full"
      onSubmit={handleSubmit(navigateToProposta)}
    >
      <>
        <h2 className="text-[2rem] font-semibold text-center text-primary-main">
          A inclusão foi confirmada com sucesso!
        </h2>
      </>
      <GoForwardButton
        isMobile
        disabled={loading}
        desktopText={loading ? '' : 'Finalizar compra'}
        mobileText={loading ? '' : 'Finalizar compra'}
      />
    </form>
  );
}
