import { ReactElement, useEffect, useState } from 'react';
import { useRouteLoaderData } from 'react-router-dom';
import { useBoostedPlanDataStore } from '../../../stores/useBoostedPlanDataStore';
import { useNortheastPlanDataStore } from '../../../stores/useNortheastPlanDataStore';
import { usePlanLoadingStore } from '../../../stores/usePlanLoadingStore';
import PlanLoading from '../../icons/PlanLoading';
import { useVidaCountStore } from '../../../stores/useQuantidadeVidas';
import { useDependentValueStore } from '../../../stores/useDependentStore';
import { useValorTotalStore } from '../../../stores/usePaymentStore';

export function MainPurchaseSummaryContent(): ReactElement {
  const ServidorPlanData: any = useRouteLoaderData('token-servidor');
  const ServidorPlanDataCopy: any = useRouteLoaderData('token-servidorCopy');
  const boostedPlanData = useBoostedPlanDataStore(state => state.data);
  const northeastPlanData = useNortheastPlanDataStore(state => state.data);
  const [GDF, setGDF] = useState(true);
  const [Reembolso, setReembolso] = useState('30,00');
  const ValorTotal = useValorTotalStore(x => x.valor);
  const setValorTotal = useValorTotalStore(x => x.setValor);
  const [ValorTitular, setValorTitular] = useState('');
  const ValorDependente = useDependentValueStore(state => state.vl_total);
  const valorDep = ValorDependente.toFixed(2).replace('.', ',');

  const [data, setData] = useState<any>();
  useEffect(() => {
    if (ServidorPlanData) {
      setData(ServidorPlanData);
    } else if (ServidorPlanDataCopy) {
      setData(ServidorPlanDataCopy);
    }
  }, []);

  const [valor, setValor] = useState('0,00');
  useEffect(() => {
    if (Object.keys(boostedPlanData).length !== 0 && boostedPlanData != data) {
      //data = newPlanData;
      setData(boostedPlanData);
    } else if (
      Object.keys(northeastPlanData).length !== 0 &&
      northeastPlanData != data
    ) {
      setData(northeastPlanData);
    } else {
      if (ServidorPlanData) {
        setData(ServidorPlanData);
      } else if (ServidorPlanDataCopy) {
        setData(ServidorPlanDataCopy);
      }
    }
  }, [
    boostedPlanData,
    northeastPlanData,
    ServidorPlanData,
    ServidorPlanDataCopy,
  ]);

  const Vidas = useVidaCountStore(x => x.count);

  useEffect(() => {
    if (data) {
      const roundedNum = data?.formasPagamento[0].vl_valor;
      const formattedNum = roundedNum.replace('.', ',');
      setValor(formattedNum);
      const produto = data?.produtoComercial?.id_prodcomerc;
      if (produto == 993) {
        setReembolso('29,70');
      } else {
        setReembolso('30,00');
      }
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      const valorNumber = data.formasPagamento[0].vl_valor;
      const reembolsoNumber = parseFloat(Reembolso.replace(',', '.')) || 0;
      const valorTitular = (valorNumber - reembolsoNumber).toFixed(2);
      setValorTitular(valorTitular.replace('.', ','));
    }
  }, [Reembolso, valor]);

  useEffect(() => {
    const valorTitular = parseFloat(ValorTitular.replace(',', '.')) || 0;
    const valorCompleto = (valorTitular + ValorDependente).toFixed(2);
    setValorTotal(valorCompleto.replace('.', ','));
  }, [ValorTitular, ValorDependente]);

  const loading = usePlanLoadingStore(state => state.loading);

  return (
    <>
      <h3 className="text-high-emphasis text-xl">Resumo da compra</h3>
      <span className="flex justify-between uppercase text-medium-emphasis text-xs font-normal">
        <span className="block">
          <span className="block">Plano escolhido:</span>
          <strong className="block text-neutral-600 normal-case text-sm font-normal">
            {data && data.produtoComercial
              ? data?.produtoComercial?.nm_prodcomerc
              : ''}
          </strong>
        </span>
        <span className="block">
          <span className="block">Vidas:</span>
          <strong className="block text-neutral-600 normal-case text-sm font-normal">
            {Vidas}
          </strong>
        </span>
      </span>
      {loading ? (
        <PlanLoading />
      ) : GDF ? (
        <div className=" bg-[#F8F8FB] py-3 flex flex-col justify-center items-center ">
          <span className="uppercase text-secondary-main rounded-xl block mb-[11px]">
            Valor do plano
          </span>
          <strong className="font-normal text-base text-high-emphasis">
            R$ <span className="text-[2rem] font-semibold"> {valor} </span> por
            mês
          </strong>
          <span className="uppercase text-secondary-main rounded-xl block mb-[11px]">
            reembolso do GDF
          </span>
          <strong className="font-normal text-base text-high-emphasis">
            R$ <span className="text-[2rem] font-semibold"> {Reembolso} </span>{' '}
            por mês
          </strong>
          {Vidas > 1 && (
            <>
              <span className="uppercase text-secondary-main rounded-xl block mb-[11px]">
                Valor Titular
              </span>
              <strong className="font-normal text-base text-high-emphasis">
                R${' '}
                <span className="text-[2rem] font-semibold">
                  {ValorTitular}
                </span>{' '}
                por mês
              </strong>
              <span className="uppercase text-secondary-main rounded-xl block mb-[11px]">
                Valor Dependentes
              </span>
              <strong className="font-normal text-base text-high-emphasis">
                R$ <span className="text-[2rem] font-semibold">{valorDep}</span>{' '}
                por mês
              </strong>
            </>
          )}
          <span className="uppercase text-secondary-main rounded-xl block mb-[11px]">
            Valor Total
          </span>
          <strong className="font-normal text-base text-high-emphasis">
            R$ <span className="text-[2rem] font-semibold"> {ValorTotal} </span>{' '}
            por mês
          </strong>
        </div>
      ) : (
        <div className="h-[95px] bg-[#F8F8FB] py-3 flex flex-col justify-center items-center ">
          <span className="uppercase text-secondary-main rounded-xl block mb-[11px]">
            Valor total
          </span>
          <strong className="font-normal text-base text-high-emphasis">
            R${' '}
            <span className="text-[2rem] font-semibold">
              {data.formasPagamento[0].vl_valor.replace('.', ',')}
            </span>{' '}
            por mês
          </strong>
        </div>
      )}
      {data && data.carencias && (
        <div className="">
          <span className="text-[0.625rem] text-[#777777]">Carência</span>
          {data.carencias.map((carencias: any) => (
            <p
              key={carencias.carencia.nm_descricaocarencia}
              className="text-xs text-[#394146]"
            >
              {carencias.carencia.nm_descricaocarencia}
            </p>
          ))}
        </div>
      )}
    </>
  );
}
