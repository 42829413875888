import { ReactElement } from 'react';
import { useHirePlanForm } from '../../../../contexts/HirePlanFormProviderGdf';
import { GoForwardButton } from '../../GoForwardButton';
import { useWindowSizeStore } from '../../../../stores/useWindowSizeStore';
import { useLoadingStore } from '../../../../stores/useLoadingStore';

export function ConfirmPAP(): ReactElement {
  const { handleSubmit, navigateToProposta } = useHirePlanForm();
  const loading = useLoadingStore(state => state.loading);
  const width = useWindowSizeStore(state => state.width);

  return (
    <div className="flex flex-col items-center justify-center min-h-[calc(100vh-160px)] px-4">
      <div className="max-w-[766px] w-full flex flex-col items-center">
        <h2 className="text-[2rem] font-semibold text-primary-main mb-4 text-center">
          SUCESSO!
        </h2>
        <p className="font-normal text-lg mb-8 text-center max-w-[600px]">
          Caso o e-mail tenha sido preenchido corretamente, em breve, você
          receberá a confirmação do benefício Odontológico subsidiado pelo
          Governo do Distrito Federal.
        </p>

        <div className="w-full flex justify-center">
          <div className="w-full max-w-[300px]">
            {' '}
            {/* Container adicional para o botão */}
            <GoForwardButton
              isMobile={width < 768}
              disabled={loading}
              desktopText="VOLTAR PARA O INÍCIO"
              mobileText="INÍCIO"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
