import type { ReactElement } from 'react';

import { Button } from './Button';
import { ArrowRightIcon } from '../../components/icons/ArrowRightIcon';

const InfoBox = ({ title, info, value }) => (
  <div className="rounded-lg p-3 border border-[#E2E4FD] text-center">
    <h4 className="text-2xl text-primary-main font-bold">{title}</h4>
    <h6 className="text-xl text-medium-emphasis">{info}</h6>
    <p className="text-2xl text-new-orange font-bold">{value}</p>
  </div>
);

const ChartSection = (): ReactElement => (
  <section className="w-full bg-primary-dark p-20 max-[1000px]:p-5">
    <div className="flex flex-col items-center mt-10 gap-3">
      <h3 className="text-[40px] leading-[60px] text-center text-neutral-with max-[1200px]:text-2xl max-[1200px]:max-w-[266px]">
        Índice de Desempenho da Saúde Suplementar (IDSS)
      </h3>
      <p className="text-justify mt-3 text-[#E2E4FD] text-lg max-[900px]:text-sm">
        A ANS estabeleceu o Programa de Qualidade das Operadoras que tem como
        objetivo avaliar, anualmente, o desempenho das operadoras de planos de
        saúde. As operadoras são avaliadas compulsoriamente por meio de um
        conjunto de indicadores pré-definidos, através de dados extraídos dos
        sistemas de informações gerenciais da ANS. Esses dados são fornecidos
        pelas Operadoras, por meio de relatórios periódicos enviados para a
        Agência e que geram o Índice de Desempenho da Saúde Suplementar (IDSS).
      </p>
      <p className="text-justify mt-3 text-[#E2E4FD] text-lg max-[900px]:text-sm">
        Obtenha mais informações sobre o Programa de Qualificação de Operadoras,{' '}
        <a href="https://www.gov.br/ans/pt-br/assuntos/informacoes-e-avaliacoes-de-operadoras/qualificacao-ans">
          clicando aqui.
        </a>
      </p>
    </div>
    <div
      className="bg-white flex rounded-lg p-3 gap-2 justify-center flex-wrap mt-10 max-[900px]:justify-evenly"
      style={{ marginLeft: '10%', width: '70vw' }}
    >
      <img
        src="/assets/images/transparency/idss2024.png"
        style={{ width: '65vw' }}
      />
    </div>
    <div
      className="bg-white flex rounded-lg p-3 gap-2 justify-center flex-wrap mt-10 max-[900px]:justify-evenly"
      style={{ marginLeft: '10%', width: '70vw' }}
    >
      <img
        src="/assets/images/transparency/idss2023.png"
        style={{ width: '65vw' }}
      />
    </div>
    <div
      className="bg-white flex rounded-lg p-3 gap-2 justify-center flex-wrap mt-10 max-[900px]:justify-evenly"
      style={{ marginLeft: '10%', width: '70vw' }}
    >
      <img
        src="/assets/images/transparency/idss2022.png"
        style={{ width: '65vw' }}
      />
    </div>
    <div
      className="bg-white flex rounded-lg p-3 gap-2 justify-center flex-wrap mt-10 max-[900px]:justify-evenly"
      style={{ marginLeft: '10%', width: '70vw' }}
    >
      <img
        src="/assets/images/transparency/idss2021.png"
        style={{ width: '65vw' }}
      />
    </div>
    <div
      className="bg-white flex rounded-lg p-3 gap-2 justify-center flex-wrap mt-10 max-[900px]:justify-evenly"
      style={{ marginLeft: '10%', width: '70vw' }}
    >
      <img
        src="/assets/images/transparency/idss2020.png"
        style={{ width: '65vw' }}
      />
    </div>
    <div
      className="bg-white flex rounded-lg p-3 gap-2 justify-center flex-wrap mt-10 max-[900px]:justify-evenly"
      style={{ marginLeft: '10%', width: '70vw' }}
    >
      <img
        src="/assets/images/transparency/idss2019.png"
        style={{ width: '65vw' }}
      />
    </div>
    {/*<p className="text-center mt-3 text-[#E2E4FD] text-lg max-[900px]:text-sm">
      IDSS - Índice de Desempenho da Saúde Suplementar
    </p> */}

    <div className="flex flex-col items-center mt-10 gap-3">
      <h3 className="text-[40px] leading-[60px] text-center text-neutral-with max-[1200px]:text-2xl max-[1200px]:max-w-[266px]">
        Quer conhecer mais detalhes?
      </h3>
      <Button
        tag="a"
        href="https://www.ans.gov.br/qualificacao_consumidor/informacoes_operadora.asp?co_operadora_param=389854&cd_processamento_param=20200101#consulta_idss"
        target="_blank"
        text="Ir para portal da ANS"
        intent="borderless"
        Icon={ArrowRightIcon}
      />
    </div>
  </section>
);

export default ChartSection;
