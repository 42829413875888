import { ReactElement } from 'react';
import { useRouteLoaderData } from 'react-router-dom';
import { useWindowSizeStore } from './stores/useWindowSizeStore';
import ConfirmacaoScreen from './pages/teste/confirmationScreen';
import { BannerImage } from './components/BannerImage';
import { PageHelmet } from './components/PageHelmet';

export function ComponenteConfirmacao(): ReactElement {
  const GDFPlanData: any = useRouteLoaderData('token-servidorCopy');
  const data = GDFPlanData;

  const width = useWindowSizeStore(state => state.width);

  return (
    <div className="overflow-hidden">
      <PageHelmet title="Confirmação | OdontoGroup" />

      <BannerImage page="plan" imgSource="/assets/images/plan-banner.jpg" />
      <div className="">
        <article className="-mb-20 bg-transparent min-[748px]:-mt-[326px] -mt-[57px] max-w-[1301px] max-[1599px]:px-6 mx-auto">
          <div className="flex 2xl:gap-16 min-[748px]:gap-8 gap-16 max-[1085px]:flex-col">
            <section>
              {width >= 748 && (
                <div className="bg-[#F8F8F8] px-8 h-[88px] flex items-center justify-between rounded-t-xl border-b border-b-primary-container">
                  <img
                    src="/assets/logos/odontogroup-azul.png"
                    alt=""
                    className="2xl:h-[62px] h-[px]"
                  />
                </div>
              )}
              <ConfirmacaoScreen />
              {/* Adicionando espaço na parte inferior */}
              <div className="min-h-[200px] bg-transparent"></div>
            </section>
          </div>
        </article>
      </div>
    </div>
  );
}
