import { ReactElement, useEffect, useState } from 'react';

import { useHirePlanForm } from '../../../../contexts/HirePlanFormProviderGdf';
import { GoForwardButton } from '../../GoForwardButton';
import { useWindowSizeStore } from '../../../../stores/useWindowSizeStore';
import { CartIcon } from '../../../icons/CartIcon';
import PlanLoading from '../../../icons/PlanLoading';
import { ErrorModal } from '../../../Modal/Modal';
import { useLoadingStore } from '../../../../stores/useLoadingStore';
import { useError } from '../../../../stores/useError';

export function AceiteForm(): ReactElement {
  const { handleSubmit, onSubmit } = useHirePlanForm();

  const width = useWindowSizeStore(state => state.width);

  const loading = useLoadingStore(state => state.loading);
  const error = useError(state => state.error);
  const title = useError(state => state.title);
  const text = useError(state => state.text);

  const handleEncerrar = () => {
    // Lógica para encerrar ou redirecionar
    console.log('Encerrar');
  };

  return (
    <form
      action=""
      className="max-w-[766px] w-full"
      onSubmit={handleSubmit(onSubmit)}
    >
      {error && <ErrorModal open={true} title={title} text={text} />}
      <>
        <h2 className="text-[2rem] font-semibold text-center text-primary-main mb-10">
          Aguardando a confirmação
        </h2>
        <PlanLoading />
        <div className="flex justify-center mt-4">
          <button
            type="button"
            onClick={handleEncerrar}
            className="bg-secondary-main text-white px-4 py-2 rounded-md hover:bg-secondary-dark transition-colors w-full sm:w-auto"
          >
            Encerrar
          </button>
        </div>
      </>
    </form>
  );
}
