import {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Control,
  FieldErrors,
  UseFormClearErrors,
  SubmitHandler,
  useForm,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormSetValue,
  UseFormUnregister,
  UseFormWatch,
} from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { zodResolver } from '@hookform/resolvers/zod';

import {
  creditCardSchema,
  CreditCardSchema,
} from '../components/HirePlanForm/data/schema GDF/creditCardSchema';
import {
  debitSchema,
  DebitSchema,
} from '../components/HirePlanForm/data/schema GDF/debitSchema';
import {
  billetSchema,
  BilletSchema,
} from '../components/HirePlanForm/data/schema GDF/billetSchema';

import { useHirePlanFormStepStore } from '../stores/useHirePlanFormStepStore';
import { usePaymentStore, useValorTotalStore } from '../stores/usePaymentStore';
import { useBoostedPlanDataStore } from '../stores/useBoostedPlanDataStore';
import { useLoadingStore } from '../stores/useLoadingStore';
import { useError } from '../stores/useError';
import { useVidaCountStore } from '../stores/useQuantidadeVidas';
import { useResponseStore } from '../stores/useResponseStore';
import io from 'socket.io-client';
import { usePaPStore } from '../stores/PaPStore';

type PaymentFormSchema = CreditCardSchema & DebitSchema & BilletSchema;

interface HirePlanFormContextProps {
  register: UseFormRegister<PaymentFormSchema>;
  control: Control<PaymentFormSchema, any>;
  watch: UseFormWatch<PaymentFormSchema>;
  handleSubmit: UseFormHandleSubmit<PaymentFormSchema>;
  onSubmit: (schema: PaymentFormSchema) => void;
  goToNextForm: (schema: PaymentFormSchema) => void;
  errors: FieldErrors<PaymentFormSchema>;
  clearErrors: UseFormClearErrors<PaymentFormSchema>;
  unregister: UseFormUnregister<PaymentFormSchema>;
  setValue: UseFormSetValue<PaymentFormSchema>;
  navigateToProposta: () => void;
}

//const socket = io('http://localhost:3335');
const HirePlanFormContext = createContext({} as HirePlanFormContextProps);

interface HirePlanFormProviderProps {
  children: ReactNode;
}

export function HirePlanFormProvider({
  children,
}: HirePlanFormProviderProps): ReactElement {
  const [socket, setSocket] = useState<any>(null); // Mover para o topo
  const [retrie, setRetrie] = useState<boolean>(false); // Mover para o topo
  const [solicitacao, setSolicitacao] = useState<any | null>(null); // Mover para o topo

  const initialPlanData = useBoostedPlanDataStore(state => state.data);
  const goToNextStep = useHirePlanFormStepStore(state => state.goToNextStep);
  const paymentId = usePaymentStore(state => state.id);
  const navigate = useNavigate();
  const storedResponse = useResponseStore(x => x.response);
  const vidas = useVidaCountStore(x => x.count);
  const valorTotal = useValorTotalStore(x => x.valor);

  const setLoading = useLoadingStore(state => state.setLoading);
  const setError = useError(state => state.setError);
  const setTitle = useError(state => state.setErrorTitle);
  const setText = useError(state => state.setErrorText);
  const setResponse = useResponseStore(x => x.setResponse);
  const PaP = usePaPStore(x => x.PAP);

  interface CheckoutData {
    token: string;
    cpf?: string;
    verifica: boolean;
    nomeTitular?: string;
    dataNascimento?: string;
    emailTitular?: string;
    cep?: string;
    idUf?: string;
    endereco?: string;
    numeroCasa?: string;
    complemento?: string;
    bairro?: string;
    cidade?: string;
    celular?: string;
    nomeMae?: string;
    matricula?: string;
    idSexo?: string;
    idEstadoCivil?: string;
    formaPagamento?: object;
    proposta: string;
    valor_Mensalidade: number;
    vencimentoBoleto?: string;
    vencimentoDebito?: string;
    vencimentoConsignado?: string;
    qtdVidas: string;
    dependentes?: object;
    dependenteSamePlan: boolean;
    idBanco?: string;
    conta?: string;
    agencia?: string;
    operacao?: string;
    primeiraBoleto?: boolean;
    responsavelFinanceiro: object;
    idOrgaoExpedidor: number;
    idOrgaoExpedidorUf: number;
    idFontePagadora: number;
    orgao: number;
  }

  function getSchemaResolver() {
    switch (paymentId) {
      //Credit Card
      case 1: {
        return creditCardSchema;
      }

      case 2: {
        //Debit
        return billetSchema;
      }

      case 3: {
        //Billet
        return debitSchema;
      }

      default: {
        return creditCardSchema;
      }
    }
  }

  function removeUnusedMethod(checkoutData: CheckoutData) {
    switch (paymentId) {
      //Credit Card
      case 1: {
        return checkoutData;
      }

      case 2: {
        //Debit
        delete checkoutData['cartaoCredito'];
        return checkoutData;
      }

      case 3: {
        //Billet
        delete checkoutData['cartaoCredito'];
        //delete checkoutData['vencimentoDebito'];
        return checkoutData;
      }
    }
  }

  const calculateDueDate = (): string => {
    const today = new Date();
    today.setDate(1);
    today.setMonth(today.getMonth() + 1);

    return today.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  };

  function getPaymentData(data: PaymentFormSchema) {
    switch (paymentId) {
      case 1: {
        //Credit Card
        return {
          formaPagamento: {
            gpPagto: 1,
            idPagto: 1,
          },
        };
      }
      case 2: {
        //Debit
        return {
          formaPagamento: {
            gpPagto: 2,
            idPagto: 6, //TODO: pegar id dinamico
          },
        };
      }
      case 3: {
        //Billet
        return {
          formaPagamento: {
            gpPagto: 3,
            idPagto: 4,
          },
        };
      }
    }
  }

  const {
    register,
    handleSubmit,
    control,
    watch,
    unregister,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm<DebitSchema>({
    defaultValues: {
      payment: {
        firstPaymentBillet: false,
      },
    },
    resolver: zodResolver(getSchemaResolver()),
  });

  const goToNextForm: SubmitHandler<PaymentFormSchema> = () => {
    goToNextStep();
  };

  const onSubmit: SubmitHandler<PaymentFormSchema> = async data => {
    setLoading(true);

    const banco = watch('payment.bank');
    const conta = watch('payment.debitAcount');
    const agencia = watch('payment.agency');
    const operacao = watch('payment.operation');
    const primeiraBoleto = watch('payment.firstPaymentBillet');
    const vencimentoBoleto = watch('payment.billetDate');
    const vencimentoDebito = watch('payment.debitDate');
    const VencimentoGdf = calculateDueDate();
    const reembolso = data.user?.plano == '993' ? 29.7 : 30;

    const matricula = (
      data.user?.documents?.matricula?.replace(/[.-]/g, '') || ''
    ).padStart(8, '0');
    const socketUrl = process.env.REACT_APP_WS_URL;

    const checkoutData: CheckoutData = {
      token: initialPlanData.token,
      cpf: data.user?.documents?.cpf?.replace(/\D/g, ''),
      nomeTitular: data.user?.fullName,
      dataNascimento: data.user?.birthDate,
      emailTitular: data.user?.email,
      cep: data.user?.address?.cep,
      idUf: data.user?.address?.uf,
      verifica: true,
      endereco: data.user?.address?.street,
      numeroCasa: String(Number(data.user?.address?.number)),
      complemento: data.user?.address?.complement,
      bairro: data.user?.address?.neighborhood,
      cidade: data.user?.address?.city,
      celular: data.user?.phone,
      nomeMae: data.user?.fullMothersName,
      matricula: matricula,
      idSexo: data.user?.gender,
      idEstadoCivil: data.user?.civilStatus,
      idFontePagadora: 5,
      idOrgaoExpedidor: 6,
      idOrgaoExpedidorUf: 8,
      orgao: parseFloat(data.user?.orgao || '0'),
      formaPagamento: {
        gpPagto: 4,
        idPagto: 3,
      },
      proposta: String(Math.floor(Math.random() * 1000000000)),
      valor_Mensalidade: parseFloat(valorTotal.replace(',', '.')) + reembolso,
      vencimentoBoleto,
      vencimentoDebito,
      vencimentoConsignado: VencimentoGdf,
      qtdVidas: vidas,
      dependenteSamePlan: false,
      dependentes: data.user?.dependents?.map(dependent => ({
        nome: dependent.name,
        cpf: dependent.cpf?.replace(/\D/g, ''),
        plano: dependent.plano,
        valor_plano: dependent.plano_vl,
        dataNascimento: dependent.birthDate,
        nomeMae: dependent.mothersName,
        idSexo: dependent.gender,
        idParentesco: dependent.parentage,
        idOrgaoExpedidor: 6,
        idOrgaoExpedidorUf: 8,
      })),
      idBanco: banco,
      conta,
      agencia,
      operacao,
      primeiraBoleto: false,
      responsavelFinanceiro: {
        cpf: data.user?.documents?.cpf?.replace(/\D/g, ''),
        nome: data.user?.fullName,
        dataNascimento: data.user?.birthDate,
        email: data.user?.email,
        cep: data.user?.address?.cep,
        endereco: data.user?.address?.street,
        cidade: data.user?.address?.city,
        numero: String(data.user?.address?.number),
        complemento: data.user?.address?.complement,
        bairro: data.user?.address?.neighborhood,
        idUf: data.user?.address?.uf,
        telefone: data.user?.phone,
      },
    };

    const UrlEnvio = PaP
      ? `${process.env.REACT_APP_OG_API_URL}/ws/solicitacao`
      : process.env.REACT_APP_OG_PAYMENT_INDIVIDUAL;

    let JsonEnvio: any = checkoutData;

    if (PaP) {
      console.log('Entrando no fluxo de WebSocket.');
      if (!socketUrl) {
        console.error('WebSocket URL não definida em .env');
        setLoading(false);
        return;
      }

      // Inicialize o WebSocket diretamente
      const socketConnection = io(socketUrl);

      // Use uma Promise para aguardar a conexão do WebSocket
      await new Promise<void>((resolve, reject) => {
        socketConnection.on('connect', () => {
          JsonEnvio = {
            sender: socketConnection.id,
            payment: checkoutData,
          };
          console.log('Conectado ao WebSocket:', socketConnection.id);
          resolve(); // Resolva a Promise quando o WebSocket estiver conectado
        });

        socketConnection.on('connect_error', error => {
          console.error('Erro ao conectar ao WebSocket:', error);
          reject(error); // Rejeite a Promise em caso de erro
        });
      });

      // Configure os eventos do WebSocket
      socketConnection.on('solicitacao:update', (data: any) => {
        console.log('Atualização recebida via WebSocket:', data);

        if (data.retorno) {
          setSolicitacao(data.retorno.confirmation);

          if (data.retorno.confirmation.status === 2) {
            alert('Solicitação confirmada com sucesso!');
            navigate('/proposta', { state: data.retorno.pagamento });
            socketConnection.off('solicitacao:update');
            socketConnection.close();
            goToNextStep();
          }
        }
      });

      // Feche o WebSocket ao sair
      socketConnection.on('disconnect', () => {
        console.log('WebSocket desconectado.');
      });
    }

    try {
      console.log('Body de envio:', JsonEnvio);
      const response = await fetch(UrlEnvio, {
        headers: {
          'content-type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(JsonEnvio),
      });

      if (response.ok) {
        if (PaP) {
          goToNextStep();
        } else {
          const responseData = await response.json();
          setLoading(false);
          setError(false);
          setResponse(responseData);
          goToNextStep();
        }
      } else {
        const errorData = await response.json();
        setTitle(errorData.code || 'Erro');
        setText(errorData.error || errorData.message);
        throw new Error(errorData.error || errorData.message);
      }
    } catch (error: any) {
      console.error('Erro no envio:', error);
      setLoading(false);
      setError(true);
      setTitle(error.code || 'Erro');
      setText(error.Error || error.message);
      throw new Error(error.Error || error);
    }
  };
  const navigateToProposta = () => {
    if (storedResponse) {
      navigate('/proposta', { state: storedResponse.data });
    }
  };

  return (
    <HirePlanFormContext.Provider
      value={{
        register,
        control,
        handleSubmit,
        watch,
        onSubmit,
        goToNextForm,
        errors,
        clearErrors,
        unregister,
        setValue,
        navigateToProposta,
      }}
    >
      {children}
    </HirePlanFormContext.Provider>
  );
}

export function useHirePlanForm(): HirePlanFormContextProps {
  return useContext(HirePlanFormContext);
}
