import { ReactElement, useEffect, useState } from 'react';
import { Input } from '../../../../Input';
import { Select } from '../../../../Select';
import { Button } from '../../../../Button';
import Modal from 'react-modal';
import { useHirePlanForm } from '../../../../../contexts/HirePlanFormProviderGdf';
import { useRouteLoaderData } from 'react-router-dom';
import './functionaryForm.css';
import { SaveIcon } from '../../../../icons/SaveIcon';
import { dependentsSchema } from '../../../data/schema GDF/dependentsSchema';
import {
  changeCivilStatus,
  changeGender,
  changeParentage,
  changeParentageGDF,
  changeUF,
} from '../../../../../utils/ChangeArrays';
import { z } from 'zod';
import {
  useDependentAddDataStore,
  useDependentIdStore,
  useDependentOpenModalDataStore,
} from '../../../../../stores/useDependentStore';
import { useVidaCountStore } from '../../../../../stores/useQuantidadeVidas';
import { useBoostedPlanDataStore } from '../../../../../stores/useBoostedPlanDataStore';

function validateDependent(obj: any): boolean {
  try {
    dependentsSchema.parse(obj);
    return true;
  } catch (error) {
    if (error instanceof z.ZodError) {
      return false;
    }
    return false;
  }
}

Modal.setAppElement('#root');

export function DependentForm(): ReactElement {
  const { register, handleSubmit, onSubmit, errors, watch, setValue } =
    useHirePlanForm();
  const initialPlanData: any = useRouteLoaderData('token-servidor');
  const setAddDependent = useDependentAddDataStore(x => x.setdependentAddData);
  const boostedPlanData = useBoostedPlanDataStore(x => x.data);
  const DependentOpenModal = useDependentOpenModalDataStore(x => x.data);
  const setDependentOpenModal = useDependentOpenModalDataStore(
    x => x.setdependentOpenModalData,
  );
  const setCountVida = useVidaCountStore(x => x.setVidaCount);
  const [clinico, setClinico] = useState(false);
  const [dependentPlanData, setDependentPlanData]: any = useState();

  const Planos = [
    {
      Id: 994,
      Nome: 'OdontoClinico - apenas R$ 22,90 por Dependente',
      vl: '22,90',
    },
    {
      Id: 998,
      Nome: 'OdontoDoc - apenas R$ 32,90 por Dependente',
      vl: '32,90',
    },
    {
      Id: 999,
      Nome: 'OdontoProtese - apenas R$ 89,90 por Dependente',
      vl: '89,90',
    },
    {
      Id: 1000,
      Nome: 'OdontoOrto - apenas R$ 110,00 por Dependente',
      vl: '110,00',
    },
  ];

  changeGender(initialPlanData.listaSexos);
  changeCivilStatus(initialPlanData.listaEstadosCivil);
  const Parentesco = changeParentageGDF(initialPlanData.listaParentescos);
  const data = initialPlanData;
  const dependentes = watch('user.dependents') || [];
  const plano = watch('user.plano');
  const id = useDependentIdStore(state => state.id);
  const setId = useDependentIdStore(state => state.setId);

  const Id = id || 0;
  const dependenteData = dependentes[Id];
  const dependentPlan = watch(`user.dependents.${Id}.plano`);

  const saveDependent = () => {
    if (!clinico) {
      setValue(`user.dependents.${Id}.plano`, plano);
      setValue(
        `user.dependents.${Id}.plano_vl`,
        Planos.find(item => item.Id == Number(plano))?.vl || '22,90',
      );
    } else {
      setValue(
        `user.dependents.${Id}.plano_vl`,
        dependentPlanData.produtoComercial.formasPagamentoIndividual[0]
          .vl_valor,
      );
    }
    validateDependent(dependenteData);
    setAddDependent(true);
    alert('Dependente salvo com sucesso!');
    const quantidadeDependentes = dependentes?.length || 0;
    if (dependentes && dependentes[0].cpf) {
      setCountVida(quantidadeDependentes + 1);
    }
    setDependentOpenModal(false);
  };
  const openModal = () => {
    setDependentOpenModal(true);
    if (dependentes && dependentes[0].cpf == '') {
      setId(0);
    } else {
      setId(dependentes.length);
    }
  };

  const closeModal = () => {
    setDependentOpenModal(false);
  };
  useEffect(() => {
    setDependentOpenModal(false);
  }, []);

  useEffect(() => {
    if (plano == '993') {
      setClinico(true);
    } else {
      setClinico(false);
    }
  }, [plano]);

  useEffect(() => {
    fetchPlan(dependentPlan, boostedPlanData.vendedorId);
  }, [dependentPlan]);

  useEffect(() => {
    const quantidadeDependentes = dependentes?.length || 0;
    if (dependentes && dependentes[0] && dependentes[0].cpf) {
      setCountVida(quantidadeDependentes + 1);
    } else {
      setCountVida(1);
    }
  }, [dependentes]);

  const fetchPlan = async (planoId, VendedorId) => {
    const urlBaseApiV2 = process.env.REACT_APP_OG_API_URL;
    if (planoId && VendedorId) {
      const response = await fetch(`
        ${urlBaseApiV2}/info/getPlansSeller?vendedorId=${VendedorId}&produtoComercial=${planoId}`);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      const urlPlan = process.env.REACT_APP_OG_GET_PLAN_DETAILS_SERVER;
      const Plan = await fetch(`${urlPlan}${data.cd_Codtokenidparc}`);

      const retorno = await Plan.json();
      setDependentPlanData(retorno);
    }
  };

  return (
    <div>
      <div className="w-full relative flex min-[748px]:justify-end justify-center gap-6">
        <Button type="button" text="Adicionar Dependente" onClick={openModal} />
      </div>

      <Modal
        isOpen={DependentOpenModal}
        onRequestClose={closeModal}
        contentLabel="Formulário de Funcionário"
        className="modal"
        overlayClassName="modal-backdrop"
      >
        <h2 className="min-[748px]:text-xl text-lg font-medium text-primary-main mb-8">
          Dados do Dependente
        </h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="grid min-[748px]:grid-cols-3 gap-8">
            <div className="min-[748px]:col-span-2">
              <Input
                label="Nome"
                placeholder="Nome"
                errorMessage={errors?.user?.dependents?.[Id]?.name?.message}
                {...register(`user.dependents.${Id}.name`)}
              />
            </div>
            <Input
              activateMask
              mask="999.999.999-99"
              label="CPF"
              placeholder="CPF"
              errorMessage={errors?.user?.dependents?.[Id]?.cpf?.message}
              {...register(`user.dependents.${Id}.cpf`)}
            />
          </div>
          <div className="grid min-[748px]:grid-cols-3 gap-8">
            <Input
              activateMask
              mask="99/99/9999"
              label="Data de nascimento"
              placeholder="dd/mm/aaaa"
              errorMessage={errors?.user?.dependents?.[Id]?.birthDate?.message}
              {...register(`user.dependents.${Id}.birthDate`)}
            />
            <Select
              label="Estado civil"
              placeholder="Estado civil"
              options={[]}
              optionsFull={data.listaEstadosCivil}
              errorMessage={
                errors?.user?.dependents?.[Id]?.civilStatus?.message
              }
              {...register(`user.dependents.${Id}.civilStatus`)}
            />
            <Select
              label="Gênero"
              placeholder="Gênero"
              options={[]}
              optionsFull={data.listaSexos}
              errorMessage={errors?.user?.dependents?.[Id]?.gender?.message}
              {...register(`user.dependents.${Id}.gender`)}
            />
          </div>
          <div className="grid min-[748px]:grid-cols-3 gap-8">
            <div className="min-[748px]:col-span-2">
              <Input
                label="Nome da mãe"
                placeholder="Nome da mãe"
                errorMessage={
                  errors?.user?.dependents?.[Id]?.mothersName?.message
                }
                {...register(`user.dependents.${Id}.mothersName`)}
              />
            </div>
            <Select
              label="Parentesco"
              placeholder="Parentesco"
              options={[]}
              optionsFull={Parentesco}
              errorMessage={errors?.user?.dependents?.[Id]?.parentage?.message}
              {...register(`user.dependents.${Id}.parentage`)}
            />
          </div>
          {clinico && (
            <div className="grid min-[748px]:grid-cols-3 gap-8">
              <div className="min-[748px]:col-span-2">
                <Select
                  label="Plano"
                  options={[]}
                  optionsFull={Planos}
                  placeholder="Plano"
                  errorMessage={errors.user?.[Id]?.plano?.message}
                  {...register(`user.dependents.${Id}.plano`)}
                />
              </div>
            </div>
          )}
        </form>
        <div
          style={{
            display: 'flex',
            marginTop: '25px',
            flexDirection: 'row-reverse',
          }}
        >
          <Button
            type="button"
            onClick={closeModal}
            text="Cancelar"
            style={{ background: '#CC0505' }}
          />
          <Button
            type="button"
            intent="secondary"
            Icon={SaveIcon}
            onClick={saveDependent}
            text="Salvar Dependente"
            style={{ marginRight: '20px' }}
          />
        </div>
        <div
          style={{
            display: 'flex',
            marginTop: '25px',
            flexDirection: 'row-reverse',
          }}
        >
          <Button type="button" text="Fechar" onClick={closeModal} />
        </div>
      </Modal>
    </div>
  );
}

export default DependentForm;
