import { z } from 'zod';

export const fileSchema = z
  .object({
    file: z
      .instanceof(FileList)
      .nullable()
      .refine(fileList => fileList && fileList.length > 0, {
        message: 'Arquivo é obrigatório',
      }),
  })
  .superRefine((data, ctx) => {
    const fileList = data.file;

    if (fileList) {
      const file = fileList[0];

      if (file.size > 5000000) {
        ctx.addIssue({
          path: ['file'],
          message: 'Tamanho máximo de 5MB',
          code: z.ZodIssueCode.custom,
        });
      }

      if (!['image/jpeg', 'application/pdf'].includes(file.type)) {
        ctx.addIssue({
          path: ['file'],
          message: 'Somente arquivos JPEG ou PDF são permitidos',
          code: z.ZodIssueCode.custom,
        });
      }
    }
  });

const address = z.object({
  cep: z.optional(
    z
      .string()
      .min(9, { message: 'Campo obrigatório' })
      .regex(/^[0-9]{5}-[0-9]{3}$/, { message: 'CEP inválido' }),
  ),
  neighborhood: z.optional(z.string().nonempty('Campo obrigatório')),
  uf: z.optional(z.string().min(1, { message: 'Campo obrigatório' })),
  city: z.optional(z.string().min(1, { message: 'Campo obrigatório' })),
  street: z.optional(z.string().min(1, { message: 'Campo obrigatório' })),
  number: z.optional(z.string().min(1, { message: 'Campo obrigatório' })),
  complement: z.optional(z.string()),
});

export const dependenteSchema = z.object({
  nome: z.string().min(1, 'Nome é obrigatório'),
  cpf: z.string().min(11, 'CPF deve conter 11 caracteres'),
  dtNascimento: z.string().min(1, 'Data de nascimento é obrigatória'),
  rg: z.string().optional(),
  orgao: z.string().min(1, 'Orgão é obrigatório'),
  orgaoUF: z.string().min(1, 'UF do orgão é obrigatório'),
  parentesco: z.string().min(1, 'Parentesco é obrigatório'),
  cns: z.string().optional(),
  sexo: z.string().min(1, 'Sexo é obrigatório'),
  estCivil: z.string().min(1, 'Estado civil é obrigatório'),
  motherName: z.string().min(1, 'Nome da mãe é obrigatório'),
  vinculo: fileSchema.optional(),
});

export const funcionarioSchema = z.object({
  nome: z.string().min(1, 'Nome é obrigatório'),
  cpf: z.string().min(11, 'CPF deve conter 11 caracteres'),
  dtNascimento: z.string().min(1, 'Data de nascimento é obrigatória'),
  matricula: z.string().optional(),
  rg: z.string().min(1, 'RG é obrigatório'),
  orgao: z.string().min(1, 'Orgão é obrigatório'),
  orgaoUF: z.string().min(1, 'UF do orgão é obrigatório'),
  cns: z.string().optional(),
  sexo: z.string().min(1, 'Sexo é obrigatório'),
  estCivil: z.string().min(1, 'Estado civil é obrigatório'),
  telefoneRes: z.string().nullable(),
  Celular: z.string().min(10, 'Celular deve conter pelo menos 10 caracteres'),
  email: z.string().email('Email inválido'),
  motherName: z.string().min(1, 'Nome da mãe é obrigatório'),
  address: address.optional(),
  dependentes: z.array(dependenteSchema).optional(),
  vinculo: fileSchema.optional(),
});

export interface Dependente {
  nome: string;
  cpf: string;
  dtNascimento: string;
  rg: string;
  orgao: string;
  orgaoUF: string;
  parentesco: string;
  cns: string;
  sexo: string;
  estCivil: string;
  motherName: string;
  email: string;
}

interface Address {
  cep: string;
  neighborhood: string;
  uf: string;
  city: string;
  street: string;
  number: string;
  complement: string;
}

export interface Funcionario {
  nome: string;
  cpf: string;
  dtNascimento: string;
  matricula: string;
  rg: string;
  orgao: string;
  orgaoUF: string;
  UF: string;
  cns: string;
  sexo: string;
  estCivil: string;
  telefoneRes?: string | null;
  Celular: string;
  email: string;
  motherName: string;
  dependentes?: Dependente[];
  address?: Address;
}

export const schema = z.object({
  funcionario: funcionarioSchema,
});

export type FuncionarioSchema = z.infer<typeof schema>;
