import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import { router } from './config/router';
import './styles/global.css';
import VLibras from 'react-vlibras';

const App = () => {
  useEffect(() => {
    setTimeout(() => {
      const script = document.createElement('script');
      script.src = 'https://vlibras.gov.br/app/vlibras-plugin.js';
      script.async = true;
      document.body.appendChild(script);
    }, 2000); // Aguarda 2 segundos antes de carregar o VLibras
  }, []);

  return (
    <>
      <VLibras safeInit />
      <RouterProvider router={router} />
    </>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(<App />);
