import React, { ReactElement, useEffect, useState } from 'react';

import * as z from 'zod';

import { useUpdateDependentForm } from '../../../../../../contexts/UpdateDependentFormProviderGdf';
import { useDependentCountStore } from '../../../../../../stores/useDependentCountStore';
import { useHirePlanFormStepStore } from '../../../../../../stores/useHirePlanFormStepStore';
import { Button } from '../../../../../Button';
import { PlusIcon } from '../../../../../icons/PlusIcon';
import { dependentsSchema } from '../../../../data/schema/dependentsSchema';

import { validateCEP } from '../../../../../../utils/ValidateCEP';
import {
  changeCivilStatus,
  changeGender,
  changeUF,
  changeOrgao,
  changeParentage,
  changeParentageGDF,
} from '../../../../../../utils/ChangeArrays';
import { GoForwardButton } from '../../../../GoForwardButton';
import { DependentCard } from './DependentCard';
import { DependentForm } from './DependentFormTeste';
import { Input } from '../../../../../Input';
import { Select } from '../../../../../Select';
import { useRouteLoaderData } from 'react-router-dom';
import { useBoostedPlanDataStore } from '../../../../../../stores/useBoostedPlanDataStore';
import { usePlanLoadingStore } from '../../../../../../stores/usePlanLoadingStore';
import {
  useDependentIdStore,
  useDependentOpenModalDataStore,
  useDependentValueStore,
} from '../../../../../../stores/useDependentStore';
import { useVidaCountStore } from '../../../../../../stores/useQuantidadeVidas';
import { error } from 'console';

function validateDependent(obj: any): boolean {
  try {
    dependentsSchema.parse(obj);
    return true;
  } catch (error) {
    if (error instanceof z.ZodError) {
      return false;
    }
    return false;
  }
}

export function BeneficiaryForm(): ReactElement {
  const goToNextStep = useHirePlanFormStepStore(state => state.goToNextStep);
  const data: any = useRouteLoaderData('token-dependente');
  const { watch, setValue, errors, handleSubmit, goToNextForm } =
    useUpdateDependentForm();
  const setBoostedPlanData = useBoostedPlanDataStore(
    state => state.setBoostPlanData,
  );
  const valorDependentes = useDependentValueStore(x => x.setValorTotal);
  const setLoading = usePlanLoadingStore(state => state.setLoading);
  const [hasDependentes, setHasDependentes] = useState(false);
  const [hasNewDependentes, setHasNewDependentes] = useState(false);
  const countVida = useVidaCountStore(x => x.count);
  const setCountVida = useVidaCountStore(x => x.setVidaCount);
  const setId = useDependentIdStore(state => state.setId);
  const planData = useBoostedPlanDataStore(x => x.data);
  const Parentesco = changeParentageGDF(data.listaParentescos);

  const plano = watch('Associado.plano');
  const Associado = watch('Associado');
  const dependentes = watch('Associado.dependentes');
  const newDependents = watch('dependents');
  const erros = errors;
  const [teste, setTeste] = useState(false);

  const Planos = [
    {
      Id: 993,
      Nome: 'OdontoClinico - apenas R$ 0,30 para o Titular',
      Tipo: 'OdontoClinico',
    },
    {
      Id: 998,
      Nome: 'OdontoDoc - apenas R$ 2,90 para o Titular',
      Tipo: 'OdontoDoc',
    },
    {
      Id: 999,
      Nome: 'OdontoProtese - apenas R$ 59,90 para o Titular',
      Tipo: 'OdontoProtese',
    },
    {
      Id: 1000,
      Nome: 'OdontoOrto - apenas R$ 80,00 para o Titular',
      Tipo: 'OdontoOrto',
    },
  ];

  useEffect(() => {
    console.log('data: ', data);
  }, [planData]);

  useEffect(() => {
    console.log('erros: ', erros);
    console.log('Associado: ', Associado);
    console.log('dependentes existentes: ', dependentes);
    console.log('novos Dependentes: ', newDependents);
  }, [teste]);

  useEffect(() => {
    setCountVida(0);
    console.log('dependentes1: ', dependentes);
    if (dependentes && dependentes[0]) {
      setHasDependentes(true);
    } else {
      setHasDependentes(false);
    }
  }, []);

  useEffect(() => {
    console.log('dependentes: ', newDependents);
    if (newDependents && newDependents[0] && newDependents[0].name) {
      setHasNewDependentes(true);
      const Total = calcularDependenteVlTotal(dependentes || []);
      valorDependentes(Total);
    } else {
      setHasNewDependentes(false);
      valorDependentes(0);
    }
  }, [countVida]);

  const setDependentOpenModal = useDependentOpenModalDataStore(
    x => x.setdependentOpenModalData,
  );

  const fetchPlan = async (planoId, VendedorId) => {
    //const plano = 124;
    console.log('planoId: ', planoId);
    const urlBaseApiV2 = process.env.REACT_APP_OG_API_URL;
    if (planoId && VendedorId) {
      const plano = planoId == 124 ? 119 : planoId;
      const response = await fetch(`
        ${urlBaseApiV2}/info/dependente/getPlansSeller?vendedor=${VendedorId}&cdS4e=${plano}`);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      console.log('consulta de plano: ', data);
      const urlPlan = process.env.REACT_APP_OG_GET_PLAN_DETAILS_SERVER;
      const Plan = await fetch(`${urlPlan}${data.cd_Codtokenidparc}`);

      const retorno = await Plan.json();
      console.log('retornou: ', retorno);
      setBoostedPlanData(retorno);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPlan(plano, data.vendedorId);
  }, [plano]);

  const editClick = (index: number) => {
    setId(index);
    setDependentOpenModal(true);
  };

  function calcularDependenteVlTotal(dependentes: any[]): number {
    return dependentes.reduce((total, dependente) => {
      const valor = parseFloat(dependente.plano_vl.replace(',', '.'));
      return total + (isNaN(valor) ? 0 : valor);
    }, 0);
  }

  const removeClick = (ind: number) => {
    const currentDependentes = watch('dependents') || [];
    const updatedDependentes = currentDependentes.filter(
      (_, index) => index !== ind,
    );
    setValue('dependents', updatedDependentes);
  };
  function verifyClick() {
    setTeste(!teste);
  }
  function handleValidate() {
    if (countVida < 1) {
      alert('Deve adicionar pelo menos 1 novo dependente para proseguir!!!!!');
    } else {
      if (newDependents && newDependents[0] && newDependents[0].cpf == '') {
        removeClick(0);
      }
      handleSubmit(goToNextForm)();
    }
  }

  changeGender(data.listaSexos);
  changeCivilStatus(data.listaEstadosCivil);
  changeParentage(data.listaParentescos);

  return (
    <div className="w-full flex flex-col items-center">
      <h2 className="min-[748px]:text-xl text-lg font-semibold text-primary-main mb-4 mt-4">
        Dados do Titular
      </h2>
      <div className="w-full min-[748px]:mt-4 rounded-xl p-2">
        <div className="grid grid-cols-4 gap-6">
          <div className="col-span-2">
            <label>Nome:</label> {Associado.name}
          </div>
          <div className="col-span-1">
            <label>CPF:</label> {Associado.cpf}
          </div>
          <div className="col-span-1">
            <label>Matricula:</label> {Associado.matricula}
          </div>
          <div className="col-span-1"></div>
        </div>
        <div
          className="grid grid-cols-4 gap-6"
          style={{ marginBottom: '15px' }}
        >
          <div className="col-span-2">
            <label>Plano:</label>{' '}
            {planData?.produtoComercial?.nm_prodcomerc || ''}
          </div>
          <div className="col-span-1">
            <label>Secretaria:</label> {Associado.departamento}
          </div>
        </div>
        <h2 className="min-[748px]:text-[2rem] text-2xl font-semibold text-primary-main">
          Dependentes
        </h2>
        {hasDependentes && (
          <div className="w-full min-[748px]:mt-4 rounded-xl p-2">
            <h3 className="min-[748px]:text-[1.5rem] text-2xl font-semibold text-primary-main">
              Existentes
            </h3>
            <table width="98%" style={{ margin: '2%' }}>
              <thead>
                <tr>
                  <th style={{ textAlign: 'center' }}>Nome</th>
                  <th style={{ textAlign: 'center' }}>sexo</th>
                  <th style={{ textAlign: 'center' }}>Parentesco</th>
                  <th style={{ textAlign: 'center' }}>Plano</th>
                </tr>
              </thead>
              <tbody>
                {dependentes?.map((dependente, index) => (
                  <React.Fragment key={index}>
                    <tr>
                      <td style={{ textAlign: 'center' }}>{dependente.name}</td>
                      <td style={{ textAlign: 'center' }}>
                        {dependente.gender == 'F' ? 'Feminino' : 'Masculino'}
                      </td>
                      <td style={{ textAlign: 'center' }}>
                        {data.listaParentescos.find(
                          x =>
                            x.Id ==
                            (dependente.parentage == 3
                              ? 10
                              : dependente.parentage == 4
                              ? 11
                              : dependente.parentage),
                        )?.Sigla || ''}
                      </td>
                      <td style={{ textAlign: 'center' }}>
                        {Planos.find(x => x.Id == dependente.plano || 993)
                          ?.Tipo || 'OdontoClinico'}
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <div
        className="w-full min-[748px]:mt-4 rounded-xl p-2"
        style={{
          marginTop: '20px',
          display: 'flex',
          flexDirection: 'row',
          gap: '40px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <h3 className="min-[748px]:text-[1.5rem] text-2xl font-semibold text-primary-main">
          Novos
        </h3>
        <p className="font-normal text-secondary-main mt-1">
          Você pode adicionar quantos dependentes desejar.
        </p>
        <DependentForm />
      </div>
      {hasNewDependentes && (
        <div className="w-full min-[748px]:mt-4 rounded-xl p-2">
          <table width="98%" style={{ margin: '2%' }}>
            <thead>
              <tr>
                <th style={{ textAlign: 'center' }}>Nome</th>
                <th style={{ textAlign: 'center' }}>CPF</th>
                <th style={{ textAlign: 'center' }}>Nascimento</th>
                <th style={{ textAlign: 'center' }}>Parentesco</th>
                <th style={{ textAlign: 'center' }}>Plano</th>
                <th style={{ textAlign: 'center' }}>Ações</th>
              </tr>
            </thead>
            <tbody>
              {newDependents?.map((dependente, index) => (
                <React.Fragment key={index}>
                  <tr>
                    <td style={{ textAlign: 'center' }}>{dependente.name}</td>
                    <td style={{ textAlign: 'center' }}>{dependente.cpf}</td>
                    <td style={{ textAlign: 'center' }}>
                      {dependente.birthDate}
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      {Parentesco.find(
                        x => x.Id == Number(dependente.parentage),
                      )?.Sigla || ''}
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      {(() => {
                        let planoId;
                        switch (dependente.plano) {
                          case '124':
                            planoId = 993;
                            break;
                          case '252':
                            planoId = 998;
                            break;
                          case '206':
                            planoId = 999;
                            break;
                          case '251':
                            planoId = 1000;
                            break;
                          default:
                            planoId = 993;
                            break;
                        }
                        return (
                          Planos.find(x => x.Id == planoId)?.Tipo ||
                          'OdontoClinico'
                        );
                      })()}
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      <button type="button" onClick={() => editClick(index)}>
                        Editar
                      </button>
                      {' / '}
                      <button type="button" onClick={() => removeClick(index)}>
                        Remover
                      </button>
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <GoForwardButton addBackButton onForwardClick={handleValidate} />
    </div>
  );
}
