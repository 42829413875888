import { ReactElement, useEffect, useState } from 'react';

import { HirePlanForm } from '.';
import { useWindowSizeStore } from '../../../stores/useWindowSizeStore';
import { Button } from '../../Button';
import { LeadingIcon } from '../../icons/LeadingIcon';
import { SideBar } from '../../SideBar';
import ExpandableList from '../../ExpandedList';
import { useRouteLoaderData } from 'react-router-dom';
import { useHirePlanFormStepStore } from '../../../stores/useHirePlanFormStepStore';

interface Especiality {
  nomeEspecialidade: string;
  idEspecialidade: number;
  procedures: Procedure[];
}

interface Procedure {
  codigoEspecialidade: number;
  nomeProcedimento: string;
}

export function HirePlanFormSection(): ReactElement {
  const GDFPlanData: any = useRouteLoaderData('token-servidorCopy');
  const data = GDFPlanData;

  const clearStep = useHirePlanFormStepStore(state => state.clearStep);

  const width = useWindowSizeStore(state => state.width);

  const [sideBarOpen, setSideBarOpen] = useState(false);

  const [especialities, setEspecialities] = useState<Especiality[]>([]);

  const buildEspecialityList = (listEspeciality: Especiality[]) => {
    listEspeciality.forEach(especiality => {
      especiality.procedures = [];
    });

    return listEspeciality;
  };

  const findEspecialities = async () => {
    const getEspecialities = await fetch(
      `${process.env.REACT_APP_GET_DENTISTS_SPECIALTY}?token=${
        process.env.REACT_APP_TOKEN_S4E
      }&idEstado=${6}&pagina=${1}`,
    ).then(response => response.json());

    setEspecialities(buildEspecialityList(getEspecialities.dataObject.dados));
  };

  const findEspeciality = (
    idEspecialidade: number,
  ): Especiality | undefined => {
    return especialities.find(esp => esp.idEspecialidade == idEspecialidade);
  };

  const buildListOfProcedures = (listProcedures: Procedure[]) => {
    listProcedures.forEach(proc => {
      const especiality = findEspeciality(proc.codigoEspecialidade);

      if (especiality) {
        especiality.procedures.push(proc);
      }
    });
  };

  /* const findProcedures = async () => {
    const getProcedures = await fetch(
      `${process.env.REACT_APP_GET_SPECIALTY_PLAN}?token=${
        process.env.REACT_APP_TOKEN_S4E
      }&idPlano=${
        data.produtoComercial.id_produto_s4_e_c
      }&pagina=${1}&limite=${100}`,
    ).then(response => response.json());

    buildListOfProcedures(getProcedures.dataObject.dados);
  }; */

  useEffect(() => {
    clearStep();
    findEspecialities();
  }, []);

  /* useEffect(() => {
    findProcedures();
  }, [especialities]); */

  const openSideBar = () => {
    setSideBarOpen(true);
  };

  return (
    <section className="max-w-[1500px] w-full mb-64">
      {width >= 748 && (
        <div className="bg-[#F8F8F8] px-8 h-[88px] flex items-center justify-between rounded-t-xl border-b border-b-primary-container">
          {/* <h2 className="2xl:text-[2rem] text-lg text-primary-main font-semibold">
            Plano Orto
          </h2> */}
          <img
            src="/assets/logos/odontogroup-azul.png"
            alt=""
            className="2xl:h-[62px] h-[px]"
          />
          <Button
            text="Mostrar coberturas"
            intent="tertiary"
            Icon={LeadingIcon}
            onClick={openSideBar}
          />
          <SideBar setIsSideOpen={setSideBarOpen} slideOpen={sideBarOpen}>
            {especialities.map(especiality => {
              if (especiality.procedures.length > 0) {
                return (
                  <ExpandableList
                    key={especiality.idEspecialidade}
                    title={especiality.nomeEspecialidade}
                    contentList={
                      especiality.procedures &&
                      especiality.procedures.map(proc => proc.nomeProcedimento)
                    }
                  ></ExpandableList>
                );
              }
            })}
          </SideBar>
        </div>
      )}
      <HirePlanForm />
    </section>
  );
}
