import { ReactElement, useEffect, useState } from 'react';
import { Input } from '../../../Input';
import { Select } from '../../../Select';
import { Button } from '../../../Button';
import Modal from 'react-modal';
import { useHireBussinessPlanForm } from '../../../../contexts/HirePlanBussinessFormProvider';
import { useRouteLoaderData } from 'react-router-dom';
import './DependentForm.css';
import { SaveIcon } from '../../../icons/SaveIcon';
import { dependenteSchema } from '../data/schema/functionarysSchema';
import {
  changeCivilStatus,
  changeGender,
  changeParentage,
  changeUF,
} from '../../../../utils/ChangeArrays';
import { z } from 'zod';
import {
  useDependentAddDataStore,
  useDependentIdStore,
  useDependentOpenModalDataStore,
} from '../../../../stores/useDependentStore';
import { useVidaCountStore } from '../../../../stores/useQuantidadeVidas';
import { useBoostedPlanDataStore } from '../../../../stores/useBoostedPlanDataStore';
import { useFunctionaryIdStore } from '../../../../stores/useFunctionaryStore';

function validateDependent(obj: any): boolean {
  try {
    dependenteSchema.parse(obj);
    return true;
  } catch (error) {
    if (error instanceof z.ZodError) {
      return false;
    }
    return false;
  }
}

Modal.setAppElement('#root');

export function DependentForm(): ReactElement {
  const { register, handleSubmit, onSubmit, errors, watch, goToNextForm } =
    useHireBussinessPlanForm();
  const initialPlanData: any = useRouteLoaderData('token-empresa');
  const setAddDependent = useDependentAddDataStore(x => x.setdependentAddData);
  const boostedPlanData = useBoostedPlanDataStore(x => x.data);
  const DependentOpenModal = useDependentOpenModalDataStore(x => x.data);
  const setDependentOpenModal = useDependentOpenModalDataStore(
    x => x.setdependentOpenModalData,
  );
  const setCountVida = useVidaCountStore(x => x.setVidaCount);

  changeGender(initialPlanData.listaSexos);
  changeCivilStatus(initialPlanData.listaEstadosCivil);
  changeParentage(initialPlanData.listaParentescos);
  const data = initialPlanData;
  const FuncId = useFunctionaryIdStore(state => state.id);
  const dependentes = watch(`user.funcionarios.${FuncId}.dependentes`) || [];
  const id = useDependentIdStore(state => state.id);
  const setId = useDependentIdStore(state => state.setId);

  const Id = id || 0;
  const dependenteData = dependentes[Id];

  const saveDependent = () => {
    console.log('incluindo dependente: ', dependenteData);
    const validou = validateDependent(dependenteData);
    console.log('erros: ', errors);
    console.log('validou: ', validou);
    if (validou) {
      setAddDependent(true);
      alert('Dependente salvo com sucesso!');
      const quantidadeDependentes = dependentes?.length || 0;
      if (dependentes && dependentes[0].cpf) {
        setCountVida(quantidadeDependentes + 1);
      }
      setDependentOpenModal(false);
    } else {
      handleSubmit(goToNextForm)();
    }
  };
  const openModal = () => {
    setDependentOpenModal(true);
    if (dependentes && dependentes[0].cpf == '') {
      setId(0);
    } else {
      setId(dependentes.length);
    }
  };

  const closeModal = () => {
    setDependentOpenModal(false);
  };
  useEffect(() => {
    setDependentOpenModal(false);
  }, []);

  useEffect(() => {
    const quantidadeDependentes = dependentes?.length || 0;
    if (dependentes && dependentes[0] && dependentes[0].cpf) {
      setCountVida(quantidadeDependentes + 1);
    } else {
      setCountVida(1);
    }
  }, [dependentes]);

  return (
    <div>
      <div className="w-full relative flex min-[748px]:justify-end justify-center gap-6">
        <Button type="button" text="Adicionar Dependente" onClick={openModal} />
      </div>

      <Modal
        isOpen={DependentOpenModal}
        onRequestClose={closeModal}
        contentLabel="Formulário de Funcionário"
        className="modal"
        overlayClassName="modal-backdrop"
      >
        <h2 className="min-[748px]:text-xl text-lg font-medium text-primary-main mb-8">
          Dados do Dependente
        </h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="grid min-[748px]:grid-cols-3 gap-8">
            <div className="min-[748px]:col-span-2">
              <Input
                label="Nome"
                placeholder="Nome"
                errorMessage={
                  errors?.user?.funcionarios?.[FuncId]?.dependentes?.[Id]?.nome
                    ?.message
                }
                {...register(
                  `user.funcionarios.${FuncId}.dependentes.${Id}.nome`,
                )}
              />
            </div>
            <Input
              activateMask
              mask="999.999.999-99"
              label="CPF"
              placeholder="CPF"
              errorMessage={
                errors?.user?.funcionarios?.[FuncId]?.dependentes?.[Id]?.cpf
                  ?.message
              }
              {...register(`user.funcionarios.${FuncId}.dependentes.${Id}.cpf`)}
            />
          </div>
          <div className="grid min-[748px]:grid-cols-3 gap-8">
            <Input
              label="RG"
              placeholder="RG"
              errorMessage={
                errors?.user?.funcionarios?.[FuncId]?.dependentes?.[Id]?.rg
                  ?.message
              }
              {...register(`user.funcionarios.${FuncId}.dependentes.${Id}.rg`)}
            />
            <Select
              label="Órgão expedidor"
              placeholder="Órgão"
              options={[]}
              optionsFull={data.listaOrgaosExpedidor}
              errorMessage={
                errors?.user?.funcionarios?.[FuncId]?.dependentes?.[Id]?.orgao
                  ?.message
              }
              {...register(
                `user.funcionarios.${FuncId}.dependentes.${Id}.orgao`,
              )}
            />
            <Select
              label="UF do órgão expedidor"
              placeholder="UF do órgão"
              options={[]}
              optionsFull={data.listaUFS}
              errorMessage={
                errors?.user?.funcionarios?.[FuncId]?.dependentes?.[Id]?.orgaoUF
                  ?.message
              }
              {...register(
                `user.funcionarios.${FuncId}.dependentes.${Id}.orgaoUF`,
              )}
            />
          </div>
          <div className="grid min-[748px]:grid-cols-3 gap-8">
            <Input
              activateMask
              mask="99/99/9999"
              label="Data de nascimento"
              placeholder="dd/mm/aaaa"
              errorMessage={
                errors?.user?.funcionarios?.[FuncId]?.dependentes?.[Id]
                  ?.dtNascimento?.message
              }
              {...register(
                `user.funcionarios.${FuncId}.dependentes.${Id}.dtNascimento`,
              )}
            />
            <Select
              label="Estado civil"
              placeholder="Estado civil"
              options={[]}
              optionsFull={data.listaEstadosCivil}
              errorMessage={
                errors?.user?.funcionarios?.[FuncId]?.dependentes?.[Id]
                  ?.estCivil?.message
              }
              {...register(
                `user.funcionarios.${FuncId}.dependentes.${Id}.estCivil`,
              )}
            />
            <Select
              label="Gênero"
              placeholder="Gênero"
              options={[]}
              optionsFull={data.listaSexos}
              errorMessage={
                errors?.user?.funcionarios?.[FuncId]?.dependentes?.[Id]?.sexo
                  ?.message
              }
              {...register(
                `user.funcionarios.${FuncId}.dependentes.${Id}.sexo`,
              )}
            />
          </div>
          <div className="grid min-[748px]:grid-cols-3 gap-8">
            <div className="min-[748px]:col-span-2">
              <Input
                label="Nome da mãe"
                placeholder="Nome da mãe"
                errorMessage={
                  errors?.user?.funcionarios?.[FuncId]?.dependentes?.[Id]
                    ?.motherName?.message
                }
                {...register(
                  `user.funcionarios.${FuncId}.dependentes.${Id}.motherName`,
                )}
              />
            </div>
            <Select
              label="Parentesco"
              placeholder="Parentesco"
              options={[]}
              optionsFull={initialPlanData.listaParentescos}
              errorMessage={
                errors?.user?.funcionarios?.[FuncId]?.dependentes?.[Id]
                  ?.parentesco?.message
              }
              {...register(
                `user.funcionarios.${FuncId}.dependentes.${Id}.parentesco`,
              )}
            />
          </div>
        </form>
        <div
          style={{
            display: 'flex',
            marginTop: '25px',
            flexDirection: 'row-reverse',
          }}
        >
          <Button
            type="button"
            onClick={closeModal}
            text="Cancelar"
            style={{ background: '#CC0505' }}
          />
          <Button
            type="button"
            intent="secondary"
            Icon={SaveIcon}
            onClick={saveDependent}
            text="Salvar Dependente"
            style={{ marginRight: '20px' }}
          />
        </div>
        <div
          style={{
            display: 'flex',
            marginTop: '25px',
            flexDirection: 'row-reverse',
          }}
        >
          <Button type="button" text="Fechar" onClick={closeModal} />
        </div>
      </Modal>
    </div>
  );
}

export default DependentForm;
