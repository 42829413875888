import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { io } from 'socket.io-client';

const SolicitacaoScreen: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [solicitacao, setSolicitacao] = useState<any | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [socket, setSocket] = useState<any>(null);
  const [retrie, setRetrie] = useState<boolean>(false);
  const socketUrl = process.env.REACT_APP_WS_URL;
  const urlBaseApiV2 = process.env.REACT_APP_OG_API_URL;

  useEffect(() => {
    // Criar a conexão WebSocket ao montar o componente
    if (!socketUrl) {
      console.error('WebSocket URL não definida em .env');
      return;
    }
    const socketConnection = io(socketUrl);
    // Armazenar a instância do socket
    setSocket(socketConnection);
    // Escutar eventos WebSocket para atualizar a solicitação
    socketConnection.on('solicitacao:update', (data: any) => {
      console.log('Atualização recebida via WebSocket:', data.confirmation);
      console.log('Solicitação:', solicitacao);
      if (data.confirmation) {
        console.log('entrou aqui');
        setSolicitacao(data.confirmation);
      }
    });

    // Limpar a conexão WebSocket e remover o ouvinte de evento ao desmontar o componente
    if (solicitacao && solicitacao.status === 'confirmed') {
      return () => {
        socketConnection.off('solicitacao:update');
        socketConnection.close();
      };
    }
  }, [solicitacao, retrie]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      console.log('socket:', socket);
      if (socket && socket.id) {
        const id = socket.id; // Agora o ID do socket estará disponível
        console.log('ID do socket:', id);

        const response = await axios.post<any>(
          `${urlBaseApiV2}/ws/solicitacao`,
          {
            email,
            sender: id,
            payment: {
              nomeTitular: 'Vitão',
              celular: '61983717410',
            },
          },
        );
        console.log('resposta Criação:', response.data);
        setSolicitacao(response.data.confirmation);
        alert('Solicitação enviada! Aguarde a confirmação.');
      } else {
        setError('Erro ao obter o ID do socket.');
      }
    } catch (err) {
      setRetrie(!retrie);
      setError('Erro ao enviar solicitação.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ marginTop: '100px', textAlign: 'center' }}>
      <h1>Solicitação</h1>
      <form onSubmit={handleSubmit} style={{ marginBottom: '20px' }}>
        <input
          type="email"
          placeholder="Digite seu e-mail"
          value={email}
          onChange={e => setEmail(e.target.value)}
          required
          style={{ padding: '8px', marginRight: '10px' }}
        />
        <button
          type="submit"
          disabled={loading}
          style={{ padding: '8px 15px' }}
        >
          {loading ? 'Enviando...' : 'Enviar'}
        </button>
      </form>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {solicitacao && (
        <div
          style={{
            marginTop: '20px',
            padding: '10px',
            border: '1px solid #ccc',
          }}
        >
          <p>
            <strong>Solicitação ID:</strong> {solicitacao.id}
          </p>
          <p>
            <strong>Status:</strong> {solicitacao.status}
          </p>
        </div>
      )}
    </div>
  );
};

export default SolicitacaoScreen;
