import { ReactElement, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Select } from '../../components/Select';
import Toggle from 'react-toggle';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { Button } from '../../components/Button';
import { EditIcon } from '../../components/icons/EditIcon';
import { Input } from '../../components/Input';
import { changesubjects } from '../../utils/ChangeArrays';
import axios from 'axios';

const cpfRegex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;

const faleConoscoFormSchema = z.object({
  isClient: z.boolean().optional(),
  name: z.string().min(1, { message: 'Campo obrigatório' }),
  cpf: z.string().regex(cpfRegex, { message: 'CPF inválido' }).optional(),
  phone: z.string(),
  //.regex(/^\(\d{2}\) \d{5}-\d{4}$/, { message: 'Telefone inválido' }),
  subject: z.string(),
  email: z
    .string()
    .min(1, { message: 'Campo obrigatório' })
    .email({ message: 'Email inválido' }),
  message: z.string().min(1, { message: 'Campo obrigatório' }),
  subjectDetail: z.string(),
  solicitanteData: z.string(),
});

type FaleConoscoFormSchema = z.infer<typeof faleConoscoFormSchema>;

const url = `${process.env.REACT_APP_OG_API_URL}/register/faleconosco`;

export default function FaleConoscoForm(): ReactElement {
  const { register, handleSubmit, formState, setValue, watch } =
    useForm<FaleConoscoFormSchema>({
      defaultValues: { isClient: false },
      resolver: zodResolver(faleConoscoFormSchema),
    });

  const { errors } = formState;
  const [resp, setResp] = useState<{
    done: boolean;
    error: boolean;
    msg: string;
  }>({} as any);
  const [load, setLoad] = useState(false);
  const [subjectDetailOptions, setSubjectDetailOptions] = useState<string[]>(
    [],
  );
  const subject = watch('subject');
  const isClient = watch('isClient');
  const cpf = watch('cpf');
  const [disableToggle, setDisableToggle] = useState(false);
  const [disableSubjectAndDetail, setDisableSubjectAndDetail] = useState(false);
  const [subjects, setSubjects] = useState([]);
  const [detailedSubjects, setDetailedSubjects] = useState([]);
  const [file, setFile] = useState<File | null>(null); // Estado para o arquivo
  const [titular, setTitular] = useState(false);
  const [dependente, setDependente] = useState(false);
  const [success, setSuccess] = useState(false);
  const [protocolo, setProtocolo] = useState(0);

  const postRequest = async (url, data) => {
    const resp = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then(resp => resp.json());
    return resp.dados;
  };
  const getRequest = async url => {
    const resp = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(resp => resp.json());
    const retorno =
      (await resp.dados.find(item =>
        item.dependentes.some(
          dependente =>
            dependente.codigoGrauParentesco === 1 &&
            (dependente.codigoSituacao === 1 ||
              dependente.codigoSituacao === 16),
        ),
      )) || resp.dados[0];
    console.log('retorno: ', retorno);
    return retorno;
  };

  useEffect(() => {
    const url = `${process.env.REACT_APP_URL_BASE}/SYS/Services/Vendedor.aspx/Assuntos`;
    const token = process.env.REACT_APP_TOKEN_S4E_V2;
    const data = {
      token: token,
      tipoSolicitanteId: 2,
    };
    postRequest(url, data).then(resp => {
      changesubjects(resp);
      setSubjects(resp);
      setValue('solicitanteData', '7021');
      console.log(resp);
    });
  }, []);

  useEffect(() => {
    if (subject) {
      const url = `${process.env.REACT_APP_URL_BASE}/SYS/Services/Vendedor.aspx/assuntosdetalhados`;
      const token = process.env.REACT_APP_TOKEN_S4E_V2;
      const data = {
        token: token,
        tipoSolicitanteId: 2,
        assuntoID: subject,
      };
      console.log('data: ', data);
      postRequest(url, data).then(resp => {
        changesubjects(resp);
        setDetailedSubjects(resp);
        console.log(resp);
      });
    }
  }, [subject]);

  useEffect(() => {
    const cpfRegex = cpf?.replaceAll('.', '').replace('-', '');
    if (cpfRegex && cpfRegex.length == 11 && (dependente || titular)) {
      consultaCliente(cpfRegex);
    }
  }, [cpf, titular, dependente]);

  const consultaCliente = async (cpf: string) => {
    let urlBase = `${process.env.REACT_APP_URL_BASE}/v2/api/Associados?token=${process.env.REACT_APP_TOKEN_S4E_V2}&`;
    if (titular) {
      urlBase = urlBase + `cpfAssociado=${cpf}`;
    } else if (dependente) {
      urlBase = urlBase + `cpfDependente=${cpf}`;
    }
    getRequest(urlBase).then(resp => {
      if (resp) {
        setValue('name', resp.nome);
        if (resp.contatos) {
          const telefone = resp.contatos.find(x => x.tipo === 'Celular');
          const email = resp.contatos.find(x => x.tipo === 'E-mail');
          setValue('phone', telefone.descricaoContato);
          setValue('email', email.descricaoContato);
        }
        const solicitanteId = resp.dependentes.find(
          x => x.codigoGrauParentesco === 1,
        );
        setValue('solicitanteData', String(solicitanteId.codigoDependente));
        const solicitante = watch('solicitanteData');
        console.log('solicitante: ', solicitante);
        console.log('erros: ', errors);
      } else {
        setValue('name', '');
        setValue('phone', '');
        setValue('email', '');
      }
    });
  };

  const onSubmit = async (data: FaleConoscoFormSchema) => {
    setLoad(true);
    console.log('erros: ', errors);
    const formData = new FormData();

    formData.append('nome', data.name);
    formData.append('email', data.email);
    formData.append('telefone', data.phone);
    formData.append('descricaoOcorrencia', data.message);
    formData.append('motivoDetalhado', data.subjectDetail);
    formData.append('solicitante', JSON.stringify(data.solicitanteData));

    // Só adiciona o arquivo se ele existir
    if (file) {
      formData.append('file', file);
    }

    try {
      const response = await axios.post(url, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      if (response.data.protocolo) {
        setResp({
          done: true,
          error: false,
          msg: response.data.message ?? 'Mensagem Enviada Com Sucesso!!!',
        });
        setProtocolo(response.data.protocolo);
        setSuccess(true);
      }
    } catch (error) {
      console.error(error);
      setResp({
        done: true,
        error: true,
        msg: 'Erro ao enviar mensagem. Verifique os campos e tente novamente.',
      });
    } finally {
      setLoad(false);
    }
  };

  return (
    <>
      {!success ? (
        <>
          <form id="ouvidoria-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-6 mt-4">
              {!disableToggle && (
                <div className="flex items-center gap-4">
                  <label className="font-medium">Você já é cliente?</label>
                  <Toggle
                    defaultChecked={isClient}
                    onChange={() => setValue('isClient', !isClient)}
                  />
                  {isClient && (
                    <div className="flex gap-4">
                      <div>
                        <input
                          type="radio"
                          id="titular"
                          checked={titular}
                          onChange={() => {
                            setTitular(true);
                            setDependente(false);
                          }}
                          className="mr-2"
                        />
                        <label htmlFor="titular">Titular</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="dependente"
                          checked={dependente}
                          onChange={() => {
                            setTitular(false);
                            setDependente(true);
                          }}
                          className="mr-2"
                        />
                        <label htmlFor="dependente">Dependente</label>
                      </div>
                    </div>
                  )}
                </div>
              )}

              <div className="grid grid-cols-1 gap-6">
                {isClient && (
                  <div className="flex flex-col gap-4">
                    <Input
                      label="CPF"
                      placeholder="000.000.000-00"
                      activateMask
                      mask={'999.999.999-99'}
                      errorMessage={errors.cpf?.message as any}
                      {...register('cpf')}
                    />
                  </div>
                )}
                <Input
                  label="Nome"
                  placeholder="Nome"
                  errorMessage={errors.name?.message as any}
                  {...register('name')}
                />
              </div>

              <div className="grid min-[748px]:grid-cols-2 grid-cols-1 min-[748px]:gap-8 gap-6">
                <Input
                  label="E-mail"
                  type="email"
                  placeholder="sample@email.com"
                  errorMessage={errors.email?.message as any}
                  {...register('email')}
                />
                <Input
                  label="Telefone"
                  placeholder="(00) 00000-0000"
                  activateMask
                  mask={'(99) 99999-9999'}
                  errorMessage={errors.phone?.message as any}
                  {...register('phone')}
                />
              </div>

              {!disableSubjectAndDetail && (
                <>
                  <div className="grid grid-cols-1 gap-8">
                    <Select
                      label="Assunto"
                      placeholder="Selecione um assunto"
                      options={[]}
                      optionsFull={subjects}
                      errorMessage={errors.subject?.message}
                      {...register('subject')}
                    />
                  </div>

                  <div className="grid grid-cols-1 gap-8">
                    <Select
                      label="Assunto Detalhado"
                      placeholder="Assunto Detalhado"
                      options={[]}
                      optionsFull={detailedSubjects}
                      errorMessage={errors.subjectDetail?.message}
                      {...register('subjectDetail')}
                    />
                  </div>
                </>
              )}

              <div className="grid min-[748px]:grid-cols-1 grid-cols-1 gap-16 w-full">
                <Input
                  label="Mensagem"
                  textArea={true}
                  placeholder="Detalhar situação"
                  errorMessage={errors.message?.message as any}
                  {...register('message')}
                />
              </div>
            </div>
            {resp.done && (
              <p
                className={`mt-1 mb-2 text-base ${
                  !resp.error ? 'text-success-main' : 'text-destructive-main'
                }`}
              >
                {resp.msg}
              </p>
            )}
            <div className="flex gap- items-center mt-4 ml-12">
              <label className="cursor-pointer bg-primary-main text-white px-4 py-2 rounded-md hover:bg-primary-dark transition-colors ml-40">
                Anexar Arquivo
                <input
                  type="file"
                  className="hidden"
                  onChange={e => setFile(e.target.files?.[0] || null)}
                />
              </label>
              <Button
                disabled={load}
                className="ml-auto" // Adicionando margin-right de 4px
                text="Registrar Mensagem"
                intent="primary"
                Icon={EditIcon}
                type="submit"
              />
            </div>
          </form>
        </>
      ) : (
        <div
          className="flex items-center justify-center"
          style={{
            minHeight: '35vw',
            flexDirection: 'column',
            fontSize: 'x-large',
          }}
        >
          <div>
            <h1>Mensagem enviada com sucesso</h1>
          </div>
          <div>
            <p>
              Protocolo Gerado: <b>{protocolo}</b>
            </p>
          </div>
        </div>
      )}
    </>
  );
}
