import type { ReactElement } from 'react';

import DownloadBox from './DownloadBox';

const FinancialSection = (): ReactElement => (
  <section className="w-full p-5 bg-new-gray">
    <h3
      className="text-[40px] leading-[60px] text-center text-neutral-with max-[1200px]:text-2xl max-[1200px]:max-w-[266px]"
      style={{ color: '#272d65' }}
    >
      Demonstrativos Financeiros
    </h3>
    <div className="flex justify-center gap-5 max-[840px]:flex-col mb-10">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-5 max-[799px]:w-full">
        <DownloadBox
          title="Demonstrativo 2024"
          href="https://www.odontogroup.com.br/wp-content/uploads/2025/03/OdontoGroup-Demonstracoes-Contabeis-2024.pdf"
        />
        <DownloadBox
          title="Demonstrativo 2023"
          href="https://www.odontogroup.com.br/wp-content/uploads/2024/03/OdontoGroup-Demonstracoes-Contabeis-2023.pdf"
        />
        <DownloadBox
          title="Demonstrativo 2022"
          href="https://www.odontogroup.com.br/wp-content/uploads/2023/08/Demonstracoes_contabeis_2022.pdf"
        />
        <DownloadBox
          title="Demonstrativo 2021"
          href="https://www.odontogroup.com.br/wp-content/uploads/2022/05/demonstrativo_contabil_2021.pdf"
        />
        <DownloadBox
          title="Demonstrativo 2020"
          href="https://www.odontogroup.com.br/documentos/Demo2020.pdf"
        />
        <DownloadBox
          title="Demonstrativo 2019"
          href="https://www.odontogroup.com.br/documentos/Demonstrativo_2019.pdf"
        />
        <DownloadBox
          title="Demonstrativo 2018"
          href="https://www7.odontogroup.com.br/documentos/Demonstrativo%20de%20Administra%C3%A7%C3%A3o%20-%20OdontoGroup%202018.pdf"
        />
        <DownloadBox
          title="Demonstrativo 2017"
          href="https://www7.odontogroup.com.br/documentos/Demonstrativo%20de%20Administra%C3%A7%C3%A3o%20-%20OdontoGroup%202017.pdf"
        />
      </div>
    </div>
    <h3
      className="text-[40px] leading-[60px] text-center text-neutral-with max-[1200px]:text-2xl max-[1200px]:max-w-[266px]"
      style={{ color: '#272d65' }}
    >
      Outras Informações
    </h3>
    <div className="flex justify-center gap-5 max-[840px]:flex-col mb-10">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-5 max-[799px]:w-full">
        <DownloadBox
          title="Notificação por Inadimplência do Beneficiário"
          href="https://www.gov.br/ans/pt-br/assuntos/consumidor/notificacao-por-inadimplencia-do-beneficiario"
        />
        <DownloadBox
          title="Tipos de Contratação de Plano Privado de Saúde"
          href="https://www.odontogroup.com.br/wp-content/uploads/anexos/RN_509_-_ANEXO_1.pdf"
        />
        <DownloadBox
          title="Manual de Orientação para Contratação de Plano de Saúde"
          href="https://www.odontogroup.com.br/wp-content/uploads/anexos/MPS_20250122.pdf"
        />
        <DownloadBox
          title="Guia de Leitura  Contratual"
          href="https://www.odontogroup.com.br/wp-content/uploads/anexos/GLC_20250122.pdf"
        />
        <DownloadBox
          title="Planos da Operadora"
          href="https://www.odontogroup.com.br/wp-content/uploads/anexos/Lista_Planos_OdontoGroup_20250122_Portal.pdf"
        />
      </div>
    </div>
  </section>
);

export default FinancialSection;
