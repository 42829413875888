import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { GoForwardButton } from '../../components/HirePlanForm/ServidorGDF/GoForwardButton';
import Loading from '../../components/icons/Loading';
import { CartIcon } from '../../components/icons/CartIcon';

interface Solicitacao {
  id: string;
  status: string;
  associado: any;
}

const ConfirmacaoScreen: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [solicitacao, setSolicitacao] = useState<Solicitacao | any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [enviando, setEnviando] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [aceitouTermos, setAceitouTermos] = useState<boolean>(false);
  const navigate = useNavigate();
  const urlBaseApiV2 = process.env.REACT_APP_OG_API_URL;

  // Função para formatar CPF
  const formatarCPF = (cpf: string | null | undefined): string => {
    if (!cpf) return '';
    const numerosCPF = cpf.toString().replace(/\D/g, '');
    if (numerosCPF.length !== 11) return cpf.toString();
    return numerosCPF.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  };

  // Função para formatar CEP
  const formatarCEP = (cep: string | null | undefined): string => {
    if (!cep) return '';
    const numerosCEP = cep.toString().replace(/\D/g, '');
    if (numerosCEP.length !== 8) return cep.toString();
    return numerosCEP.replace(/(\d{5})(\d{3})/, '$1-$2');
  };

  // Função para formatar a data
  const formatarData = (dataString: string) => {
    try {
      const data = new Date(dataString);
      if (isNaN(data.getTime())) {
        return dataString;
      }
      const dia = String(data.getDate()).padStart(2, '0');
      const mes = String(data.getMonth() + 1).padStart(2, '0');
      const ano = data.getFullYear();
      return `${dia}/${mes}/${ano}`;
    } catch (error) {
      console.error('Erro ao formatar data:', error);
      return dataString;
    }
  };

  useEffect(() => {
    const fetchSolicitacao = async () => {
      try {
        const response = await axios.get<any>(
          `${urlBaseApiV2}/ws/confirmar?id=${id}`,
        );
        setSolicitacao(response.data.retorno);
      } catch (err) {
        setError('Erro ao carregar solicitação.');
      } finally {
        setLoading(false);
      }
    };

    fetchSolicitacao();
  }, [id]);

  const handleConfirmar = async (status: number) => {
    try {
      const browserInfo = {
        userAgent: navigator.userAgent,
        platform: navigator.platform,
        language: navigator.language,
      };

      const ipResponse = await fetch('https://api64.ipify.org?format=json');
      const ipData = await ipResponse.json();
      const userIp = ipData.ip;

      const requestData = {
        id,
        status,
        browser_info: browserInfo,
        ip: userIp,
      };

      const corretorDetail = await axios.post(
        `${urlBaseApiV2}/ws/confirmar`,
        requestData,
      );

      const updatedSolicitacao = {
        ...solicitacao,
        status: status === 2 ? 'confirmed' : 'canceled',
      };
      setSolicitacao(updatedSolicitacao);

      return corretorDetail.data;
    } catch (err: any) {
      console.log('erro: ', err.response.data.message);
      throw err.response.data.message;
    }
  };

  const handleConfirmarClick = async () => {
    try {
      setEnviando(true);
      const corretorData = await handleConfirmar(2);
      alert('Solicitação processada com sucesso!');
      navigate('/proposta', { state: corretorData.retorno.pagamento });
    } catch (err: any) {
      console.error('Erro ao confirmar:', err);
      alert('erro: ' + err);
      setEnviando(false);
    }
  };

  const handleCancelarClick = async () => {
    try {
      await handleConfirmar(3);
      alert('Processo cancelado com sucesso!');
    } catch (err) {
      console.error('Erro ao cancelar:', err);
      setError('Erro ao processar o cancelamento.');
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <p>Carregando...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <p className="text-red-500">{error}</p>
      </div>
    );
  }

  return (
    <div className="bg-white min-h-screen p-4 md:pt-4 md:px-14 md:pb-14 flex flex-col md:shadow-card md:rounded-b-xl items-center">
      {/* Seção de informações da solicitação */}
      <div className="bg-gray-50 p-5 rounded-lg mb-5 w-full md:w-4/5">
        <p>
          <strong>Solicitação ID:</strong> {id}
        </p>
        <p>
          <strong>Status:</strong> {solicitacao.status}
        </p>
      </div>

      {/* Seção de dados do titular */}
      <div className="bg-gray-50 p-5 rounded-lg mb-5 w-full md:w-4/5">
        <h2 className="text-xl font-bold text-gray-800 mb-4">
          Dados do Titular
        </h2>
        <div className="space-y-3">
          <div className="flex flex-col md:flex-row border-b border-gray-200 pb-3">
            <span className="font-bold md:w-1/3">Nome do titular</span>
            <span className="md:w-2/3">{solicitacao.titularData.nome}</span>
          </div>
          <div className="flex flex-col md:flex-row border-b border-gray-200 pb-3">
            <span className="font-bold md:w-1/3">CPF</span>
            <span className="md:w-2/3">
              {formatarCPF(solicitacao.titularData.cpf)}
            </span>
          </div>
          <div className="flex flex-col md:flex-row border-b border-gray-200 pb-3">
            <span className="font-bold md:w-1/3">Data de nascimento</span>
            <span className="md:w-2/3">
              {formatarData(solicitacao.titularData.nascimento)}
            </span>
          </div>
          <div className="flex flex-col md:flex-row border-b border-gray-200 pb-3">
            <span className="font-bold md:w-1/3">Nome da mãe</span>
            <span className="md:w-2/3">{solicitacao.titularData.mae}</span>
          </div>
          <div className="flex flex-col md:flex-row border-b border-gray-200 pb-3">
            <span className="font-bold md:w-1/3">CEP</span>
            <span className="md:w-2/3">
              {formatarCEP(solicitacao.titularData.cep)}
            </span>
          </div>
          <div className="flex flex-col md:flex-row border-b border-gray-200 pb-3">
            <span className="font-bold md:w-1/3">Endereço</span>
            <span className="md:w-2/3">{solicitacao.titularData.endereco}</span>
          </div>
          <div className="flex flex-col md:flex-row border-b border-gray-200 pb-3">
            <span className="font-bold md:w-1/3">Complemento</span>
            <span className="md:w-2/3">
              {solicitacao.titularData.complemento}
            </span>
          </div>
          <div className="flex flex-col md:flex-row">
            <span className="font-bold md:w-1/3">Valor Total</span>
            <span className="md:w-2/3">
              R$ {solicitacao.titularData.valorTotal.replace('.', ',')}
            </span>
          </div>
        </div>
      </div>

      {/* Seção de dependentes */}
      <div className="bg-gray-50 p-5 rounded-lg mb-5 w-full md:w-4/5">
        <h2 className="text-xl font-bold text-gray-800 mb-4">Dependentes</h2>
        {!Array.isArray(solicitacao.titularData.dependentes) ||
        solicitacao.titularData.dependentes.length == 0 ? (
          <p className="text-center text-gray-600">
            Nenhum dependente cadastrado.
          </p>
        ) : (
          <div className="overflow-x-auto">
            <table className="min-w-full">
              <thead>
                <tr className="border-b border-gray-200">
                  <th className="text-left p-3">Nome</th>
                  <th className="p-3 hidden sm:table-cell">CPF</th>
                  <th className="p-3 hidden md:table-cell">Data Nasc.</th>
                  <th className="p-3 hidden lg:table-cell">Parentesco</th>
                  <th className="p-3 hidden lg:table-cell">Plano</th>
                </tr>
              </thead>
              <tbody>
                {solicitacao.titularData.dependentes.map(
                  (dependente, index) => (
                    <tr
                      key={index}
                      className="border-b border-gray-200 text-sm"
                    >
                      <td className="p-3">{dependente.nome}</td>
                      <td className="p-3 hidden sm:table-cell">
                        {formatarCPF(dependente.cpf)}
                      </td>
                      <td className="p-3 hidden md:table-cell">
                        {formatarData(dependente.nascimento)}
                      </td>
                      <td className="p-3 hidden lg:table-cell">
                        {dependente.parentesco}
                      </td>
                      <td className="p-3 hidden lg:table-cell">
                        {dependente.plano}
                      </td>
                    </tr>
                  ),
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>

      {/* Termo de aceite */}
      <div className="bg-gray-50 p-5 rounded-lg mb-5 w-full md:w-4/5">
        <h2 className="text-2xl font-semibold text-primary-main mb-4">
          Termo de aceite
        </h2>
        <p className="text-justify mb-4">
          Confirmo minha adesão ao Auxílio Odontológico oferecido pelo Governo
          do Distrito Federal, conforme disposto no Diário Oficial de julho de
          2024 (Lei Nº 7.524 e Decreto Nº 46.102 de agosto de 2024).
        </p>
        <p className="text-justify mb-4">
          O plano odontológico é 99% subsidiado para servidores titulares
          estaduais da administração direta, autárquica e fundacional do DF e
          está sendo disponibilizado através da Odontogroup Sistema de Saúde
          Ltda., operadora credenciada.
        </p>
        <p className="text-justify mb-6">
          Estou ciente que o plano não possui carência e requer uma permanência
          mínima de 12 meses. Reforço que estou ciente de que os meus dados
          pessoais serão tratados conforme as disposições da Lei Geral de
          Proteção de Dados (LGPD).
        </p>
        <div className="flex items-center mb-6">
          <input
            type="checkbox"
            id="aceiteTermos"
            checked={aceitouTermos}
            onChange={e => setAceitouTermos(e.target.checked)}
            className="mr-3 h-5 w-5"
          />
          <label htmlFor="aceiteTermos" className="text-gray-800">
            Concordo com os termos acima.
          </label>
        </div>
      </div>

      {/* Botões de ação */}
      <div className="flex flex-col sm:flex-row justify-center gap-4 w-full md:w-4/5">
        <button
          onClick={handleCancelarClick}
          className="w-full sm:w-auto mt-4 bg-red-600 hover:bg-red-700 text-white font-semibold py-3 px-6 rounded-lg transition-colors duration-200"
        >
          Cancelar
        </button>
        <div className="w-full sm:w-auto">
          <GoForwardButton
            disabled={!aceitouTermos || enviando}
            Icon={enviando ? Loading : CartIcon}
            desktopText={enviando ? '' : 'Confirmar'}
            mobileText={enviando ? '' : 'Confirmar'}
            onForwardClick={handleConfirmarClick}
          />
        </div>
      </div>
    </div>
  );
};

export default ConfirmacaoScreen;
